import React, { useEffect, useState } from "react";
import RangeSlider from "../../../components/rangeSlider/RangeSlider";
import { Form, Formik } from "formik";
import Button from "../../../components/button/Button";
import CardDivision from "../../../components/cardDivision/CardDivision";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_SLOT, HANDS } from "../../../constanst/constants";
import {
  postSendPoints,
  setSuccessSendingPoints,
} from "../../../store/slices/pointsSlice";
import TotalCard from "../../../components/totalCard/TotalCard";
import { setInitial } from "../../../store/slices/stepSlice";
import { useTranslation } from "react-i18next";
import {
  fetchContenderPoints,
  resetDataToEdit,
  setIsEditing,
  setSuccessFetchingContenderPoints,
} from "../../../store/slices/userSlice";
import Textarea from "../../../components/textarea/Textarea";
import { filterSlotFromPoints } from "../../../helpers/judgeHelper";
import Spinner from "../../../components/spinner/Spinner";

const PointSupervisorView = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isPosting, setIsPosting] = useState(false);
  const {
    points: { successSendingPoints, isLoadingSendPoints },
    user: {
      dataToEdit,
      isLoadingContenderPoints,
      successFetchingContenderPoints,
    },
    qrHandler: {
      qrData: { eventId, challengeId, divisionId },
    },
  } = useSelector((state) => state);

  const {
    criteriaNames,
    hands,
    challengeName,
    maxPoints,
    divisionName,
    slotStep,
    slotCant,
    points,
    eventJugdeChallengeDivisionId,
    contenderId,
    comment,
    photoURL,
  } = dataToEdit;
  const MAX = maxPoints / slotCant;

  const numbOfFingers =
    slotCant === 1
      ? [{ name: `General`, id: 0 }]
      : Array(slotCant)
          .fill()
          .map((_, i) => ({ name: `${t("FINGER")} ${i + 1}`, id: i }));

  const initialValues = [...Array(slotCant).keys()].reduce((acc, curr) => {
    const key = `slot${curr + 1}`;
    acc[key] = points[curr] || - slotStep;
    return acc;
  }, {});

  const getTotalValue = (obj) =>
    Object.values(obj)
      .filter((num) => num >= 0)
      .reduce((a, b) => a + b, 0);

  const onSubmit = (data) => {
    let objPoints = filterSlotFromPoints(data);
    Object.values(objPoints).forEach((element, index) => {
      if (element !== points[index]) {
        const body = {
          eventJudgeChallengeDivisionId: eventJugdeChallengeDivisionId,
          contenderId: contenderId,
          ...DEFAULT_SLOT,
          ...data,
        };
        dispatch(postSendPoints(body));
        setIsPosting(true);
      } else {
        backInitial();
      }
    });
  };

  useEffect(() => {
    dispatch(setSuccessSendingPoints(false));
  }, []);

  useEffect(() => {
    if (successSendingPoints && isPosting) {
      dispatch(
        fetchContenderPoints({
          params: { eventId, challengeId, divisionId, contenderId },
        })
      );
      backInitial();
    }
  }, [successSendingPoints, isPosting]);

  const backInitial = () => {
    dispatch(resetDataToEdit());
    dispatch(setInitial());
  };

  if (isLoadingSendPoints || isLoadingContenderPoints) {
    return <Spinner />;
  }

  return (
    <Formik initialValues={{ ...initialValues, comment }} onSubmit={onSubmit}>
      {({ values }) => {
        return (
          <Form className="h-full flex flex-col">
            <div className="m-2 rounded">
              <CardCompetition
                title={t("COMPETITION")}
                subtitle={challengeName}
                backAction={backInitial}
                photoURL={photoURL}
              />
              <div className="flex gap-x-1.5 my-1">
                <CardDivision title={t("DIVISION")}  subtitle={divisionName} />
                <CardDivision title={t("HAND")}  subtitle={t(HANDS[hands])} />
              </div>
              <CardDivision
                theme="dark"
                title={t("CRITERION")} 
                subtitle={criteriaNames[i18n.language]}
              />
            </div>
            <div className="m-2">
              {numbOfFingers.map((finger, i) => {
                return (
                  <RangeSlider
                    label={finger.name}
                    name={`slot${i + 1}`}
                    id={`slot${i + 1}`}
                    min={0}
                    max={MAX}
                    step={slotStep}
                    key={finger.id}
                  />
                );
              })}
            </div>
            <Textarea
              customClass="m-2"
              name="comment"
              id="comment"
              numRow={6}
              label={t("NOTES")}
            />
            <div className="flex justify-center my-6">
              <TotalCard total={getTotalValue(values)} />
            </div>
            <Button
              type="submit"
              customClass="w-full mt-auto sticky bottom-0"
              label={t("CONTINUE")}
              disabled={Object.values(values).some((ele) => ele < 0)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PointSupervisorView;
