import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  eventDataById,
  getEventData,
} from "../../service/providers/providerEvent";
import { eventParser } from "../../service/bodyParsers/eventParser";
import { parsedChallengesDivisions } from "../../helpers/eventHelper";

const initialState = {
  isLoadingEvent: true,
  eventData: [],
  eventParsed: [],
  eventId: null,
  successFetchingEventdata: false,
  hasFetchedEvent: false,
  errorFetchingEvent: false,
  isLoadingEventById: false,
  eventByIdData: {},
  onSiteChallenges: [],
  onSiteDivisions: [],
  offSiteChallenges: [],
  offSiteDivisions: [],
  virtualChallenges: [],
  virtualDivisions: [],
  digitalChallenges: [],
  digitalDivisions: [],
  successFetchingEventById: false,
  hasFetchedEventById: false,
  errorFetchingEventById: {
    error: false,
    message: "",
  },
};

const eventId = localStorage.getItem("eventId");

if (eventId) {
  initialState.eventId = parseInt(eventId);
}

export const fetchEventPoints = createAsyncThunk(
  "event/fetchEvent",
  getEventData
);

export const fetchEventById = createAsyncThunk(
  "event/eventById",
  eventDataById
);

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    updateEventId: (state, action) => {
      state.eventId = action.payload;
      localStorage.setItem("eventId", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEventPoints.pending, (state) => {
      state.isLoadingEvent = true;
      state.errorFetchingEvent = false;
    });
    builder.addCase(fetchEventPoints.fulfilled, (state, action) => {
      state.isLoadingEvent = false;
      state.errorFetchingEvent = false;
      state.eventData = action.payload;
      state.eventParsed = eventParser(action.payload);
      state.hasFetchedEvent = true;
      state.successFetchingEventdata = true;
    });
    builder.addCase(fetchEventPoints.rejected, (state, action) => {
      state.isLoadingEvent = false;
      state.errorFetchingEvent = action.error.message;
      state.hasFetchedEvent = false;
      state.successFetchingEventdata = false;
    });
    builder.addCase(fetchEventById.pending, (state) => {
      state.isLoadingEventById = true;
      state.successFetchingEventById = false;
      state.hasFetchedEventById = false;
      state.errorFetchingEventById = {
        error: false,
        message: "",
      };
    });
    builder.addCase(fetchEventById.fulfilled, (state, action) => {
      state.isLoadingEventById = false;
      state.successFetchingEventById = true;
      state.hasFetchedEventById = true;
      state.eventByIdData = action?.payload;
      const {
        onSiteChallenges,
        onSiteDivisions,
        offSiteChallenges,
        offSiteDivisions,
        virtualChallenges,
        virtualDivisions,
        digitalChallenges,
        digitalDivisions,
      } = parsedChallengesDivisions(action?.payload?.competitionTypes);
      state.onSiteChallenges = onSiteChallenges;
      state.onSiteDivisions = onSiteDivisions;
      state.offSiteChallenges = offSiteChallenges;
      state.offSiteDivisions = offSiteDivisions;
      state.virtualChallenges = virtualChallenges;
      state.virtualDivisions = virtualDivisions;
      state.digitalChallenges = digitalChallenges;
      state.digitalDivisions = digitalDivisions;
      state.errorFetchingEventById = {
        error: false,
        message: "",
      };
    });
    builder.addCase(fetchEventById.rejected, (state, action) => {
      state.isLoadingEventById = false;
      state.successFetchingEventById = false;
      state.hasFetchedEventById = false;
      state.errorFetchingEventById = {
        error: false,
        message: action.error.message,
      };
    });
  },
});

export const { updateEventId } = eventSlice.actions;

export default eventSlice.reducer;
