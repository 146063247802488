import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  putChangeUserLanguage,
} from "../../service/providers/providerUser";

const initialState = {
  lenguaje: "en",
  isLoadingChangeUserLanguage: false,
  errorSendingChangeUserLanguage: {
    error: false,
    message: "",
  },
  changeUserLanguage: null,
  successSendingChangeUserLanguage: false
};

export const changeUserLanguage = createAsyncThunk(
  "user/changeUserLanguage",
  putChangeUserLanguage
);

export const lenguajeSlice = createSlice({
  name: "lenguaje",
  initialState,
  reducers: {
    changeLanguage: (state, action) => ({
      ...state,
      lenguaje: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(changeUserLanguage.pending, (state) => {
      state.isLoadingChangeUserLanguage = true;
      state.changeUserLanguage = null;
      state.errorSendingChangeUserLanguage = {
        error: false,
        message: "",
      };
      state.successSendingChangeUserLanguage = false;
    });
    builder.addCase(changeUserLanguage.fulfilled, (state, action) => {
      state.isLoadingChangeUserLanguage = false;
      state.changeUserLanguage = action.payload;
      state.errorSendingChangeUserLanguage = {
        error: false,
        message: "",
      };
      state.successSendingChangeUserLanguage = true;
    });
    builder.addCase(changeUserLanguage.rejected, (state, action) => {
      state.isLoadingChangeUserLanguage = false;
      state.changeUserLanguage = null;
      state.errorSendingChangeUserLanguage = {
        error: true,
        message: action.error.message,
      };
      state.successSendingChangeUserLanguage = false;
    });
  }
});

export const { changeLanguage } = lenguajeSlice.actions;

export default lenguajeSlice.reducer;
