const parsedChallengesDivisions = (arr) => {
  const onSite = Array.isArray(arr)
    ? arr.find((item) => item.name === "On-site")
    : [];
  const offSite = Array.isArray(arr)
    ? arr.find((item) => item.name === "Off-site")
    : [];
  const virtual = Array.isArray(arr)
    ? arr.find((item) => item.name === "Virtual")
    : [];
  const digital = Array.isArray(arr)
    ? arr.find((item) => item.name === "Digital")
    : [];

  // OnSite
  const onSiteChallenges =
    onSite?.challenges?.map((challenge) => {
      return {
        id: challenge.id,
        value: challenge.eventChallengeId,
        label: challenge.name,
        disables: challenge.challengeidDependencies,
      };
    }) || [];

  const onSiteDivisions = [];
  onSite?.challenges?.forEach((challenge) => {
    challenge.divisions.forEach((division) => {
      if (!onSiteDivisions.find((objeto) => objeto.id === division.id)) {
        onSiteDivisions.push({
          id: division.id,
          value: division.name,
          label: division.name,
        });
      }
    });
  });

  // OffSite
  const offSiteChallenges =
    offSite?.challenges?.map((challenge) => {
      return {
        id: challenge.id,
        value: challenge.eventChallengeId,
        label: challenge.name,
        disables: challenge.challengeidDependencies,
      };
    }) || [];

  const offSiteDivisions = [];
  offSite?.challenges?.forEach((challenge) => {
    challenge.divisions.forEach((division) => {
      if (!offSiteDivisions.find((objeto) => objeto.id === division.id)) {
        offSiteDivisions.push({
          id: division.id,
          value: division.name,
          label: division.name,
        });
      }
    });
  });

  // Virtual
  const virtualChallenges =
    virtual?.challenges?.map((challenge) => {
      return {
        id: challenge.id,
        value: challenge.eventChallengeId,
        label: challenge.name,
        disables: challenge.challengeidDependencies,
      };
    }) || [];

  const virtualDivisions = [];
  virtual?.challenges?.forEach((challenge) => {
    challenge.divisions.forEach((division) => {
      if (!virtualDivisions.find((objeto) => objeto.id === division.id)) {
        virtualDivisions.push({
          id: division.id,
          value: division.name,
          label: division.name,
        });
      }
    });
  });

  // Digital
  const digitalChallenges =
    digital?.challenges?.map((challenge) => {
      return {
        id: challenge.id,
        value: challenge.eventChallengeId,
        label: challenge.name,
        disables: challenge.challengeidDependencies,
      };
    }) || [];

  const digitalDivisions = [];
  digital?.challenges?.forEach((challenge) => {
    challenge.divisions.forEach((division) => {
      if (!digitalDivisions.find((objeto) => objeto.id === division.id)) {
        digitalDivisions.push({
          id: division.id,
          value: division.name,
          label: division.name,
        });
      }
    });
  });

  return {
    onSiteChallenges,
    onSiteDivisions,
    offSiteChallenges,
    offSiteDivisions,
    virtualChallenges,
    virtualDivisions,
    digitalChallenges,
    digitalDivisions,
  };
};

// name puede ser "On-site" "Off-site" "Virtual"

const parsedChallengesByDivision = (arr, name, value) => {
  const competition = Array.isArray(arr)
    ? arr.find((item) => item.name === name)
    : [];
  const parsedChallenges = [];
  competition?.challenges?.forEach((challenge) => {
    if (challenge.divisions.some((ele) => ele.name === value)) {
      parsedChallenges.push({
        id: challenge.id,
        value: challenge.eventChallengeId,
        label: challenge.name,
        disables: challenge.challengeidDependencies,
      });
    }
  });

  return {
    parsedChallenges,
  };
};

const parsedEventForAlta = (
  email,
  userId,
  eventByIdData,
  faceToFaceData,
  noFaceToFaceData,
  virtual,
  digital
) => {
  const selectedData = {
    "On-site": faceToFaceData,
    "Off-site": noFaceToFaceData,
    Virtual: virtual,
    Digital: digital,
  };

  const competitionParsed = eventByIdData.competitionTypes.map(
    (competition) => {
      return {
        ...competition,
        challenges: competition.challenges.map((challenge) => {
          if (
            selectedData[competition.name]?.challengesSelected?.some(
              (ele) => ele === challenge.eventChallengeId
            )
          ) {
            return {
              ...challenge,
              divisions: challenge.divisions.map((div) => {
                if (
                  selectedData[competition.name].divisionSelected === div.name
                ) {
                  return {
                    ...div,
                    selected: true,
                  };
                } else {
                  return {
                    ...div,
                  };
                }
              }),
            };
          } else {
            return {
              ...challenge,
            };
          }
        }),
      };
    }
  );

  return {
    ...eventByIdData,
    userId,
    userEmail: email,
    competitionTypes: competitionParsed,
  };
};

export {
  parsedChallengesDivisions,
  parsedChallengesByDivision,
  parsedEventForAlta,
};
