import { Field } from "formik";
import React from "react";

const Textarea = ({ name, id, numRow, label, customClass, disabled }) => (
  <Field name={name}>
    {({ field }) => (
      <div className={`${customClass}`}>
        <label
          htmlFor={id}
          className="block my-2 text-sm font-bold text-gray-900"
        >
          {label}
        </label>
        <textarea
          {...field}
          id={id}
          rows={numRow}
          name={name}
          disabled={disabled}
          className="block mb-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    )}
  </Field>
);

export default Textarea;
