import React, { useState } from "react";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { useDispatch, useSelector } from "react-redux";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardCriterio from "../../../components/cardCriterio/CardCriterio";
import DeductionCard from "../../../components/deductionCard/DeductionCard";
import ModalChallenge from "../../../components/modalChallenge/ModalChallenge";
import { goBack } from "../../../store/slices/stepSlice";
import { useTranslation } from "react-i18next";

const ChallengeView = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [detail, setDetail] = useState({});
  const {
    contender: { eventSelected, competitionSelected },
  } = useSelector((state) => state);

  const { divisionName, points, deductions, photoUrl, userPosition } =
    competitionSelected.division[0];

  const totalMaxPoints = points.reduce(
    (sum, point) => sum + point.maxPoints,
    0
  );
  const totalUserPoints = points.reduce(
    (sum, point) => sum + point.userPoints,
    0
  );

  const handleClickCriterio = (point) => {
    setDetail({ ...point });
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setDetail({});
    setIsOpenModal(false);
  };

  return (
    <div className="flex flex-col bg-lilac-100">
      <ModalChallenge
        isOpen={isOpenModal}
        onCloseModal={onCloseModal}
        detail={detail}
      />
      <div className="m-2">
        <CardCompetition
          title={t("EVENT")}
          subtitle={eventSelected.eventName}
          backAction={() => dispatch(goBack())}
          photoURL={photoUrl}
        />
        <div className="flex mt-2">
          <CardDivision
            customClass="mr-2"
            title={t("DIVISION")}
            subtitle={divisionName}
          />
          <CardDivision
            theme="dark"
            title={t("TOTAL")}
            subtitle={`${totalMaxPoints} / ${totalUserPoints}`}
          />
          {eventSelected.pointsPublished && (
            <CardDivision
              customClass="ml-2"
              title={t("POSITION")}
              subtitle={userPosition}
            />
          )}
        </div>
        {points.map((point, index) => (
          <CardCriterio
            key={index}
            judgeName={point.judgeName}
            criteriaName={point.criteriaNames[i18n.language]}
            userPoints={point.userPoints}
            maxPoints={point.maxPoints}
            handleClick={() => handleClickCriterio(point)}
          />
        ))}
        {deductions?.map((deduction) => (
          <DeductionCard score={deduction.score} />
        ))}
      </div>
    </div>
  );
};

export default ChallengeView;
