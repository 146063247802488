import { useField } from "formik";
import React from "react";

const Input = ({ label, customClass, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={`w-full ${customClass}`}>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
        <input
          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          {...field}
          {...props}
        />
      </label>
      {meta.error && meta.touched && (
        <div className="font-semibold text-red-100">{meta.error}</div>
      )}
    </div>
  );
};

export default Input;
