import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const {
    auth: { isAuthenticated },
  } = useSelector((state) => state);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
export default PrivateRoute;
