import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Header from "../../../components/header/Header";
import Wizard from "../../../components/wizard/Wizard";
import CompletedProccessView from "../completedProccessView/CompletedProccessView";
import InitialViewPhotographer from "../initialViewPhotographer/InitialViewPhotographer";
import UploadPhotoView from "../uploadPhotoView/UploadPhotoView";

const MainViewPhotographer = () => {
  const navigate = useNavigate();
  const {
    photographer: { successFetchingContenderChallengesData },
    step: { completed },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!successFetchingContenderChallengesData && !completed) {
      navigate("/default-view");
    }
  }, [successFetchingContenderChallengesData]);

  const wizardProps = {
    steps: [
      {
        id: 1,
        component: <UploadPhotoView handleNextStep={() => {}} />,
      },
    ],
    initialView: {
      component: <InitialViewPhotographer />,
    },
    completedView: {
      component: <CompletedProccessView />,
    },
  };

  return (
    <div className="flex flex-col h-full">
      <Header />
      <Wizard {...wizardProps} />
    </div>
  );
};

export default MainViewPhotographer;
