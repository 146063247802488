import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import Select from "../../../components/select/Select";
import Header from "../../../components/header/Header";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../../../store/slices/countriesSlice";
import Spinner from "../../../components/spinner/Spinner";
// import { parsedCityByCountry } from "../../../helpers/countriesHelpers";
import { fetchDocTypes } from "../../../store/slices/documentSlice";
import { useTranslation } from "react-i18next";

const PersonalDataView = ({
  onSubmit,
  title,
  subtitle,
  backAction,
  firstName = "",
  lastName = "",
  address = "",
  city = "",
  documentType = "",
  documentNumber = "",
  phone = "",
  country = "",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [cityOptions, setCityOptions] = useState([]);
  const {
    countries: {
      isLoadingCountries,
      successFetchingCountries,
      // countriesData,
      countriesOptions,
    },
    docType: { isLoadingDocTypes, docTypes, successFetchingDocTypes },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!successFetchingCountries) {
      dispatch(fetchCountries());
    }
    if (!successFetchingDocTypes) {
      dispatch(fetchDocTypes());
    }
  }, []);

  // const onBlurCountry = (codCountrySelected) => {
  //   const countrySelected = countriesOptions?.find(
  //     (ele) => ele.id === Number(codCountrySelected)
  //   )?.label;
  //   const citiesParsed = parsedCityByCountry(countriesData, countrySelected);
  //   setCityOptions(citiesParsed);
  // };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("VALIDATION.REQUIRED_FIRSTNAME")),
    lastName: Yup.string().required(t("VALIDATION.REQUIRED_LASTNAME")),
    country: Yup.string().required(t("VALIDATION.REQUIRED_COUNTRY")),
    address: Yup.string().required(t("VALIDATION.REQUIRED_ADDRESS")),
    city: Yup.string().required(t("VALIDATION.REQUIRED_FIELD")),
    documentType: Yup.string().required(t("VALIDATION.REQUIRED_FIELD")),
    documentNumber: Yup.number().required(t("VALIDATION.REQUIRED_FIELD")),
    phone: Yup.number().required(t("VALIDATION.REQUIRED_FIELD")),
  });

  if (isLoadingCountries || isLoadingDocTypes) {
    return <Spinner />;
  }

  return (
    <div className="h-full">
      <Header />
      <CardCompetition
        title={title}
        subtitle={subtitle}
        backAction={backAction}
      />
      <Formik
        initialValues={{
          firstName,
          lastName,
          country,
          address,
          city,
          documentType,
          documentNumber,
          phone,
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => onSubmit(data)}
      >
        {({ isValid }) => (
          <Form className="h-full flex flex-col">
            <div className="m-2">
              <h1>{t("PERSONAL_DATA")}</h1>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                label={t("FIRSTNAME")}
                customClass="mt-3"
              />
              <Input
                type="text"
                name="lastName"
                id="lastName"
                label={t("LASTNAME")}
              />
              <Select
                label={t("COUNTRY_REPRESENTED")}
                name="country"
                id="country"
                options={countriesOptions}
                // onBlur={() => onBlurCountry(values.country)}
              />
              <Input
                type="text"
                name="city"
                id="city"
                label={t("LOCATION")}
                // options={cityOptions}
              />
              <Input
                type="text"
                name="address"
                id="address"
                label={t("ADDRESS")}
              />
              <Select
                label={t("DOCUMENT_TYPE")}
                name="documentType"
                id="documentType"
                options={docTypes}
              />
              <Input
                type="text"
                name="documentNumber"
                id="documentNumber"
                label={t("DOCUMENT_NUMBER")}
              />
              <Input
                type="text"
                name="phone"
                id="phone"
                label={t("PHONE_NUMBER")}
              />
            </div>
            <Button
              type="submit"
              disabled={!isValid}
              customClass="w-full mt-auto sticky bottom-0"
              label={t("CONTINUE")}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalDataView;
