import React from "react";
import { useTranslation } from "react-i18next";

const CompetitonDetail = ({ title, challenges, challengesSelected }) => {
  const { t } = useTranslation();

  if (challenges?.length > 0) {
    return (
      <div className="mt-4">
        <h2 className="font-bold">{title}</h2>
        {challengesSelected.length > 0 ? (
          challengesSelected?.map((challenge, index) => {
            return (
              <div className="ml-8" key={index}>
                <span>
                  {challenges.find((ele) => ele.value === challenge).label}
                </span>
              </div>
            );
          })
        ) : (
          <div className="ml-8">
            <span>{t("NONE")}</span>
          </div>
        )}
      </div>
    );
  }
};

export default CompetitonDetail;
