import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardDivision from "../../../components/cardDivision/CardDivision";
import TotalPoints from "../../../components/totalPoints/TotalPoints";
import {
  filterSlotFromPoints,
  getTotalValue,
  parsedJudgeResponse,
} from "../../../helpers/judgeHelper";
import {
  resetJudgeData,
  setCurrentIndex,
  setIsEditingJudge,
  updateActualRound,
  updateDataLength,
  updateDataToEditJudge,
  updateJudgeDataToPointing,
} from "../../../store/slices/judgeSlice";
import { ROUNDS } from "../../../constanst/constants";
import { setInitial, setIsCompleted } from "../../../store/slices/stepSlice";
import { useNavigate } from "react-router-dom";

const CompletedProcessJudgeView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {
    step: { currentStep },
    judge: {
      photoURL,
      judgeData: { round1, round2 },
    },
  } = useSelector((state) => state);

  const divisionName = round1[0]?.divisionName || round2[0]?.divisionName || "";

  const onEditCriteria = (criteria) => {
    dispatch(setIsEditingJudge(true));
    dispatch(updateDataToEditJudge(criteria));
    const parsedEditData = parsedJudgeResponse([criteria]);
    dispatch(updateJudgeDataToPointing(parsedEditData));
    const round = criteria.rounds;
    dispatch(updateActualRound(ROUNDS[round]));
    dispatch(setCurrentIndex(0));
    dispatch(updateDataLength(1));
    if (currentStep !== 0) {
      dispatch(setInitial());
    }
    dispatch(setIsCompleted(false));
  };

  const backAction = () => {
    dispatch(resetJudgeData());
    navigate("/default-view");
  };

  return (
    <div className="m-2">
      <CardCompetition
        title={t("COMPETITION")}
        subtitle={round1[0]?.challengeName || round2[0]?.challengeName}
        backAction={backAction}
        photoURL={photoURL}
      />
      <div className="my-1">
        <CardDivision title={t("DIVISION")} subtitle={divisionName} />
      </div>
      {round1.length > 0 && (
        <>
          <CardDivision theme="dark" title={t("ROUND")} subtitle={1} />
          {round1.map((criteria) => (
            <TotalPoints
              score={getTotalValue(filterSlotFromPoints(criteria.point))}
              totalPoints={criteria.maxscore}
              criterio={criteria.jugdementCriteriaNames[i18n.language]}
              onEdit={() => onEditCriteria(criteria)}
              key={criteria.criteriaid}
            />
          ))}
        </>
      )}
      {round2.length > 0 && (
        <>
          <CardDivision theme="dark" title={t("ROUND")} subtitle={2} />
          {round2.map((criteria) => (
            <TotalPoints
              score={getTotalValue(filterSlotFromPoints(criteria.point))}
              totalPoints={criteria.maxscore}
              criterio={criteria.jugdementCriteriaNames[i18n.language]}
              onEdit={() => onEditCriteria(criteria)}
              key={criteria.criteriaid}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CompletedProcessJudgeView;
