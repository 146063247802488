import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CardCompetitionModal = ({ isOpen, onClose, addDeduction, photoURL }) => {
  const [showPhoto, setShowPhoto] = useState(false);
  const { t } = useTranslation();
  const closeModal = () => {
    onClose(false);
  };

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
      setShowPhoto(false);
    }
  };

  return (
    <>
      {isOpen &&
        (!showPhoto ? (
          <div
            className="fixed inset-0 flex items-center justify-center z-50 w-full h-full bg-lilac-700"
            onClick={handleModalClick}
          >
            <div className="flex flex-col content-center justify-center">
              {addDeduction && (
                <button
                  className="bg-lilac-200 hover:bg-green-600 text-white font-bold py-2 px-4 mb-3"
                  onClick={addDeduction}
                >
                  {t("APPLY_DEDUCTION")}
                </button>
              )}
              <button
                className="bg-lilac-200 hover:bg-blue-600 text-white font-bold py-2 px-4"
                onClick={() => setShowPhoto(true)}
              >
                {t("SEE_PHOTO")}
              </button>
            </div>
          </div>
        ) : (
          <div
            className="fixed inset-0 flex items-center justify-center z-50 w-full h-full bg-lilac-700"
            onClick={handleModalClick}
          >
            {photoURL ? (
              <img src={photoURL} alt="toShow" onClick={handleModalClick} />
            ) : (
              <span className="text-white text-lg font-medium">
                {t("NO_PHOTO")}
              </span>
            )}
          </div>
        ))}
    </>
  );
};

export default CardCompetitionModal;
