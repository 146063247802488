import React from "react";
import { useTranslation } from "react-i18next";
import "./totalCard.css";

const TotalCard = ({ total }) => {
  const { t } = useTranslation();

  return (
    <span className="total-card__container text-lg font-medium text-white">{`${t(
      "TOTAL"
    )}: ${total}`}</span>
  );
};

export default TotalCard;
