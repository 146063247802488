import React from "react";
import { useTranslation } from "react-i18next";
import "./totalPoints.css";

const TotalPoints = ({ score, totalPoints, onEdit, criterio, judgeName }) => {
  const { t } = useTranslation();

  return (
    <div className="total-points__container flex flex-col items-center my-4 py-1">
      <span className="total-points__puntos text-xs font-light">
        {criterio ? `${criterio}` : t("POINTS")}
      </span>
      {judgeName && (
        <div className="flex items-center">
          <span className="font-bold mr-2">{`${t("JUDGE")}:`}</span>
          <span>{judgeName}</span>
        </div>
      )}
      {onEdit && (
        <button className="total-points__editar" onClick={onEdit}>
          {t("EDIT")}
        </button>
      )}
      <span className="total-points__values font-semibold text-3xl">{`${score}/${totalPoints}`}</span>
    </div>
  );
};

export default TotalPoints;
