import React from "react";
import Wizard from "../../../components/wizard/Wizard";
import AccessDataSignUpView from "../accessDataView/AccessDataSignUpView";
import CompletedProcessSignUpView from "../completedProcessSignUpView/CompletedProcessSignUpView";
import PersonalDataSignUpView from "../personalDataView/PersonalDataSignUpView";

const MainViewSignUp = () => {
  const wizardProps = {
    steps: [
      {
        id: 1,
        component: <PersonalDataSignUpView handleNextStep={() => {}} />,
      },
    ],
    initialView: {
      component: <AccessDataSignUpView />,
    },
    completedView: {
      component: <CompletedProcessSignUpView />,
    },
  };

  return (
    <div className="h-full">
      <Wizard {...wizardProps} />
    </div>
  );
};

export default MainViewSignUp;
