import apiClient from "../apiClient";

const uploadPhotoFile = async (
  // params: { eventId, challengeId, judgeId, contenderId },
  body
) => {
  const response = await apiClient.post(
    "Photographer/UploadPhotoFile?eventId=1&challengeId=1&contenderId=1&photographerId=1",
    body
  );
  return response.data;
}; // TODO: si no se usa borrarlo

const uploadPhotoBase64 = async (body) => {
  const response = await apiClient.post(
    "Photographer/UploadPhotoImageBase64",
    body
  );
  return response.data;
};

const getContenderChallenges = async ({
  params: { eventId, challengeId, divisionId, contenderId },
}) => {
  const response = await apiClient.get("Photographer/GetContenderChallenges", {
    params: {
      eventId,
      challengeId,
      divisionId,
      contenderId,
    },
  });
  return response.data;
};

export { uploadPhotoFile, uploadPhotoBase64, getContenderChallenges };
