import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFromSearchParams } from "../../../helpers/urlHelper";
import { useDispatch, useSelector } from "react-redux";
import { postChangePassword } from "../../../store/slices/recoverPasswordSlice";
import Spinner from "../../../components/spinner/Spinner";
import logo from "../../../assets/beauty-icon.jpeg";

const ChangePasswordView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = getFromSearchParams({ searchParams, key: "token" }) || "";
  const {
    recoverPassword: { isLoadingChangePassword, hasChangePassword },
  } = useSelector((state) => state);

  const onSubmit = ({ password }) => {
    dispatch(postChangePassword({ params: { token, newPassword: password } }));
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("VALIDATION_PASSWORD_MIN"))
      .required(t("VALIDATION_PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("VALIDATION_PASSWORD_NOT_MATCH"))
      .required(t("VALIDATION_PASSWORD_CONFIRM")),
  });

  if (isLoadingChangePassword) {
    return <Spinner />;
  }

  if (!hasChangePassword) {
    return (
      <div className="h-full">
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="flex flex-col h-full">
              <div className="flex flex-col h-full m-2">
                <img
                  src={logo}
                  alt="logo"
                  className="h-48 w-64 ml-auto mr-auto mt-20 mb-8"
                />
                <Input
                  name="password"
                  id="password"
                  type="password"
                  label={t("NEW_PASSWORD")}
                />
                <Input
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  label={t("CONFIRM_PASSWORD_LABEL")}
                />
              </div>
              <Button
                type="submit"
                customClass="w-full mt-auto fixed bottom-0"
                label={t("CHANGE_PASSWORD")}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div>
        <div className="mt-8 mx-4">{t("CHANGE_PASSWORD_SUCCESS")}</div>
        <Button
          customClass="ml-4 mt-2"
          label="Volver"
          onClick={() => navigate("/")}
        />
      </div>
    );
  }
};

export default ChangePasswordView;
