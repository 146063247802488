import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import React from "react";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import { useDispatch, useSelector } from "react-redux";
import { sendRecoverEmail } from "../../../store/slices/recoverPasswordSlice";
import Spinner from "../../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";

const RecoverEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    recoverPassword: {
      isLoadingSendRecoveryEmail,
      hasSendingRecoveryEmail,
      errorSendingRecoveryEmail: { error, message },
    },
  } = useSelector((state) => state);

  const onSubmit = (data) => {
    const { email } = data;
    dispatch(sendRecoverEmail({ params: { email } }));
  };

  if (isLoadingSendRecoveryEmail) {
    return <Spinner />;
  }

  if (!hasSendingRecoveryEmail) {
    return (
      <div className="flex flex-col h-full">
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="h-full flex flex-col">
              <h1 className="m-2">{t("FORGOT_PASSWORD?")}</h1>
              <div className="mx-2 mt-2">
                <Input
                  type="email"
                  name="email"
                  id="email"
                  label={t("INSERT_EMAIL")}
                />
              </div>
              {error && (
                <div className="ml-2 mb-2 font-bold text-xs text-red-100">
                  {message}
                </div>
              )}
              <div>
                <Button
                  type="submit"
                  customClass="ml-2"
                  label={t("RESET_PASSWORD")}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  } else {
    return (
      <div>
        <div className="mt-8 mx-4">
          {t("FORGOT_PASSWORD_MESSAGE")}
        </div>
        <Button
          customClass="ml-4 mt-2"
          label={t("BACK")}
          onClick={() => navigate("/")}
        />
      </div>
    );
  }
};

export default RecoverEmail;
