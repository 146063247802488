import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import DefaultView from "./views/commons/defaultView/DefaultView";
import Ingreso from "./views/ingreso/Ingreso";
import Login from "./views/login/Login";
import MainViewJudge from "./views/judge/mainViewJudge/MainViewJudge";
import QRHandler from "./views/commons/qrHandlre/QRHandler";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MainViewPhotographer from "./views/photographer/mainViewPhotographer/MainViewPhotographer";
import MainViewSupervisor from "./views/supervisor/mainViewSupervisor/MainViewSupervisor";
// import MainViewAlta from "./views/alta/mainViewAlta/MainViewAlta";
// import Dashboard from "./views/dashboard/Dashboard";
import MainViewSignUp from "./views/signUp/mainViewSignUp/MainViewSignUp";
import MainViewContender from "./views/contender/mainViewContender/MainViewContender";
import RecoverEmail from "./views/recoverPassword/recoverEmailView/recoverPassword";
import ChangePasswordView from "./views/recoverPassword/changePasswordView/changePasswordView";
import MainViewAlta from "./views/alta/mainViewAlta/MainViewAlta";

function App() {
  const {
    auth: { isAuthenticated },
    lenguaje: { lenguaje },
  } = useSelector((state) => state);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lenguaje);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {!isAuthenticated ? (
          <Route exact path="/" element={<Ingreso />} />
        ) : (
          <Route path="/" element={<DefaultView />} />
        )}
        <Route path="/default-view" element={<DefaultView />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/qr-handler"
          element={
            <PrivateRoute>
              <QRHandler />
            </PrivateRoute>
          }
        />
        <Route path="/photographer" element={<MainViewPhotographer />} />
        <Route path="/judge" element={<MainViewJudge />} />
        <Route path="/supervisor" element={<MainViewSupervisor />} />
        <Route path="/sign-up" element={<MainViewSignUp />} />
        <Route path="/contender" element={<MainViewContender />} />
        <Route path="/forget-password" element={<RecoverEmail />} />
        <Route path="/change-password" element={<ChangePasswordView />} />
        <Route path="/alta" element={<MainViewAlta />} />
        {/* <Route path="/dashboard" element={<Dashboard />} />
         */}
      </Routes>
    </BrowserRouter>
  );
}
export default App;
