import apiClient from "../apiClient";

const sendRecoveryEmail = async ({ params: { email } }) => {
  const response = await apiClient.get("RecoverPass/SendRecoveryEmail", {
    params: {
      email,
    },
  });
  return response.data;
};

const changePassword = async ({ params: { token, newPassword } }) => {
  const response = await apiClient.post(
    `RecoverPass/ChangePassword?token=${token}&newPassword=${newPassword}`
  );
  return response.data;
};

export { sendRecoveryEmail, changePassword };
