import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { startProcess } from "../../../store/slices/stepSlice";
import { setAltaAccessData } from "../../../store/slices/altaSlice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import Header from "../../../components/header/Header";
import { fetchUserByEmail } from "../../../store/slices/userSlice";
import Spinner from "../../../components/spinner/Spinner";

const AccessDataAltaView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    alta: { accessData },
    user: { isLoadingUserByEmail, successUserByEmail },
  } = useSelector((state) => state);
  const [userExist, setUserExist] = useState(false);
  const [loadPassword, setLoadPassword] = useState(false);

  const { email, password, confirmPassword } = accessData;

  useEffect(() => {
    if (successUserByEmail) {
      setUserExist(true);
    }
  }, [successUserByEmail]);

  useEffect(() => {
    if (userExist) {
      dispatch(startProcess({ step: 1, checking: false }));
    }
  }, [userExist]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("VALIDATION_EMAIL_MSG"))
      .required(t("EMAIL_REQUIRED")),
  });

  const validationSchema2 = Yup.object().shape({
    password: Yup.string()
      .min(6, t("VALIDATION_PASSWORD_MSG"))
      .required(t("PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("VALIDATION_PASSWORD_ERROR"))
      .required(t("CONFIRM_PASSWORD")),
  });

  const validateUserEmail = (data) => {
    dispatch(fetchUserByEmail(data.email));
    dispatch(setAltaAccessData({ ...accessData, ...data }));
    setLoadPassword(true);
  };

  const onSubmit = (data) => {
    dispatch(setAltaAccessData({ ...accessData, ...data }));
    dispatch(startProcess({ step: 1, checking: false }));
  };

  if (isLoadingUserByEmail) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col h-full">
      <Header />
      {!loadPassword ? (
        <Formik
          key={1}
          initialValues={{
            email,
          }}
          validationSchema={validationSchema}
          onSubmit={(data) => validateUserEmail(data)}
        >
          {({ isValid }) => (
            <Form className="h-full flex flex-col">
              <div className="h-full flex flex-col m-2">
                <h1>{t("ACCESS_DATA")}</h1>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  label={t("EMAIL")}
                />
              </div>
              <Button
                disabled={!isValid}
                type="submit"
                customClass="w-full fixed bottom-0"
                label={t("CONTINUE")}
              />
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          key={2}
          initialValues={{
            password,
            confirmPassword,
          }}
          validationSchema={validationSchema2}
          onSubmit={(data) => onSubmit(data)}
        >
          {({ isValid }) => (
            <Form className="h-full flex flex-col">
              <div className="h-full flex flex-col m-2">
                <h1>{t("ACCESS_DATA")}</h1>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  label={t("PASSWORD")}
                />
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  label={t("CONFIRM_PASSWORD_LABEL")}
                />
              </div>
              <Button
                disabled={!isValid}
                type="submit"
                customClass="w-full fixed bottom-0"
                label={t("CONTINUE")}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AccessDataAltaView;
