import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Wizard from "../../../components/wizard/Wizard";
import Header from "../../../components/header/Header";
import { useNavigate } from "react-router-dom";
import InitialViewSupervisor from "../initialViewSupervisor/InitialViewSupervisor";
import PointSupervisorView from "../pointSupervisorView/PointSupervisorView";
import DeductionView from "../deductionView/DeductionView";

const MainViewSupervisor = () => {
  const navigate = useNavigate();
  const {
    step: { completed },
    user: { successFetchingContenderPoints, isEditing },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!successFetchingContenderPoints && !completed && !isEditing) {
      navigate("/default-view");
    }
  }, [successFetchingContenderPoints]);

  const wizardProps = {
    steps: [
      {
        id: 1,
        component: <PointSupervisorView handleNextStep={() => {}} />,
      },
      {
        id: 2,
        component: <DeductionView handleNextStep={() => {}} />,
      },
    ],
    initialView: {
      component: <InitialViewSupervisor />,
    },
  };

  if (successFetchingContenderPoints) {
    return (
      <div className="flex flex-col h-full">
        <Header />
        <Wizard {...wizardProps} />
      </div>
    );
  }
};

export default MainViewSupervisor;
