import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Wizard from "../../../components/wizard/Wizard";
import Header from "../../../components/header/Header";
import { useNavigate } from "react-router-dom";
import InitialViewContender from "../initialViewContender/InitialViewContender";
import CompetitionViewContender from "../competitionViewCOntender/CompetitionViewContender";
import ChallengeView from "../challengeView/ChallengeView";

const MainViewContender = () => {
  const navigate = useNavigate();
  const {
    contender: {
      successFetchingContenderData,
    },
    step: { completed },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!successFetchingContenderData && !completed) {
      navigate("/default-view");
    }
  }, [successFetchingContenderData]);

  const wizardProps = {
    steps: [
      {
        id: 1,
        component: <CompetitionViewContender handleNextStep={() => {}} />,
      },
      {
        id: 2,
        component: <ChallengeView handleNextStep={() => {}} />,
      },
    ],
    initialView: {
      component: <InitialViewContender />,
    },
  };

  if (successFetchingContenderData) {
    return (
      <div className="flex flex-col h-full">
        <Header />
        <Wizard {...wizardProps} />
      </div>
    );
  }
};

export default MainViewContender;
