import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  changePassword,
  sendRecoveryEmail,
} from "../../service/providers/providerRecoverPassword";

const initialState = {
  isLoadingSendRecoveryEmail: false,
  successSendingRecoveryEmail: false,
  hasSendingRecoveryEmail: false,
  errorSendingRecoveryEmail: {
    error: false,
    message: "",
  },
  isLoadingChangePassword: false,
  successChangingPassword: false,
  hasChangePassword: false,
  errorChangePassword: {
    error: false,
    message: "",
  },
};

export const sendRecoverEmail = createAsyncThunk(
  "recoverPass/sendRecoveryEmail",
  sendRecoveryEmail
);

export const postChangePassword = createAsyncThunk(
  "recoverPass/changePassword",
  changePassword
);

const recoverPasswordSlice = createSlice({
  name: "recoverPassword",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendRecoverEmail.pending, (state) => {
      state.isLoadingSendRecoveryEmail = true;
      state.successSendingRecoveryEmail = false;
      state.hasSendingRecoveryEmail = false;
      state.errorSendingRecoveryEmail = {
        error: false,
        message: "",
      };
    });
    builder.addCase(sendRecoverEmail.fulfilled, (state) => {
      state.isLoadingSendRecoveryEmail = false;
      state.successSendingRecoveryEmail = true;
      state.hasSendingRecoveryEmail = true;
      state.errorSendingRecoveryEmail = {
        error: false,
        message: "",
      };
    });
    builder.addCase(sendRecoverEmail.rejected, (state) => {
      state.isLoadingSendRecoveryEmail = false;
      state.successSendingRecoveryEmail = false;
      state.hasSendingRecoveryEmail = false;
      state.errorSendingRecoveryEmail = {
        error: true,
        message: "This email was not found.",
      };
    });
    builder.addCase(postChangePassword.pending, (state) => {
      state.isLoadingChangePassword = true;
      state.successChangingPassword = false;
      state.hasChangePassword = false;
      state.errorChangePassword = {
        error: false,
        message: "",
      };
    });
    builder.addCase(postChangePassword.fulfilled, (state) => {
      state.isLoadingChangePassword = false;
      state.successChangingPassword = true;
      state.hasChangePassword = true;
      state.errorChangePassword = {
        error: false,
        message: "",
      };
    });
    builder.addCase(postChangePassword.rejected, (state) => {
      state.isLoadingChangePassword = false;
      state.successChangingPassword = false;
      state.hasChangePassword = false;
      state.errorChangePassword = {
        error: true,
        message: "An error occurred.",
      };
    });
  },
});

// export const { updateEventId } = eventSlice.actions;

export default recoverPasswordSlice.reducer;
