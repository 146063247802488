import apiClient from "../apiClient";

const getJudgeData = async ({
  params: { eventId, challengeId, judgeId, contenderId },
}) => {
  const response = await apiClient.get("Judge", {
    params: {
      eventId,
      challengeId,
      judgeId,
      contenderId,
    },
  });
  return response.data;
};

const sendPoints = async (body) => {
  const response = await apiClient.post("Judge/SendPoints", body);
  return response.data;
};

export { getJudgeData, sendPoints };
