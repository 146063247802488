import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setInitial, setIsCompleted } from "../../../store/slices/stepSlice";
import PersonalDataView from "../../commons/personalDataView/PersonalDataView";
import { sendNewContender } from "../../../store/slices/userSlice";
import { setPersonalDataSignUp } from "../../../store/slices/signUpSlice";

const PersonalDataSignUpView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    signUp: {
      accessData: {
        // userType, //TODO: falta mandar este parametro
        email,
        password,
      },
      personalData: {
        firstName,
        lastName,
        address,
        city,
        documentType,
        documentNumber,
        phone,
        country,
      },
    },
  } = useSelector((state) => state);

  const onSubmit = ({
    lastName,
    firstName,
    documentNumber,
    documentType,
    address,
    phone,
    city,
  }) => {
    const body = {
      mail: email,
      pass: password,
      lastname: lastName,
      firstname: firstName,
      docnumber: documentNumber.toString(),
      docid: documentType,
      street: address,
      phone: phone.toString(),
      cityName: city,
    };
    dispatch(
      setPersonalDataSignUp({
        lastname: lastName,
        firstname: firstName,
        docnumber: documentNumber.toString(),
        docid: documentType,
        street: address,
        phone: phone.toString(),
        cityid: city,
      })
    );
    dispatch(sendNewContender(body));
    dispatch(setIsCompleted(true));
  };

  return (
    <PersonalDataView
      firstName={firstName}
      lastName={lastName}
      address={address}
      city={city}
      documentType={documentType}
      documentNumber={documentNumber}
      phone={phone}
      country={country}
      title={t("REGISTRATION_FORM")}
      subtitle="beautycomp"
      backAction={() => dispatch(setInitial())}
      onSubmit={onSubmit}
    />
  );
};

export default PersonalDataSignUpView;
