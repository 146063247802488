import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContenderParticipation } from "../../service/providers/providerUser";

const initialState = {
  isLoadingContender: false,
  contenderData: [],
  successFetchingContenderData: false,
  hasFetchedContenderData: false,
  errorFetchingContenderData: {
    error: false,
    message: "",
  },
  eventSelected: "",
  competitionSelected: "",
  pointSelected: "",
};

export const fetchContenderData = createAsyncThunk(
  "contender/fetchData",
  getContenderParticipation
);

const contenderSlice = createSlice({
  name: "contender",
  initialState,
  reducers: {
    updateContenderData: (state, action) => {
      state.contenderData = action.payload;
    },
    resetContenderDataError: (state) => ({
      ...state,
      errorFetchingContenderData: {
        error: false,
        message: "",
      },
    }),
    setEventSelected: (state, action) => ({
      ...state,
      eventSelected: action.payload,
    }),
    resetEventSelected: (state) => ({
      ...state,
      eventSelected: "",
    }),
    setCompetitionSelected: (state, action) => ({
      ...state,
      competitionSelected: action.payload,
    }),
    resetCompetitionSelected: (state) => ({
      ...state,
      competitionSelected: "",
    }),
    setPointSelected: (state, action) => ({
      ...state,
      pointSelected: action.payload,
    }),
    resetPointSelected: (state) => ({
      ...state,
      pointSelected: "",
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContenderData.pending, (state) => {
      state.isLoadingContender = true;
      state.errorFetchingContenderData = {
        error: false,
        message: "",
      };
    });
    builder.addCase(fetchContenderData.fulfilled, (state, action) => {
      state.isLoadingContender = false;
      state.contenderData = action.payload;
      state.hasFetchedContenderData = true;
      state.successFetchingContenderData = true;
    });
    builder.addCase(fetchContenderData.rejected, (state, action) => {
      state.isLoadingContender = false;
      state.errorFetchingContenderData = {
        error: true,
        message: action.error.message,
      };
      state.hasFetchedContenderData = false;
      state.successFetchingContenderData = false;
    });
  },
});

export const {
  updateContenderData,
  resetContenderDataError,
  setEventSelected,
  resetEventSelected,
  setCompetitionSelected,
  resetCompetitionSelected,
  setPointSelected,
  resetPointSelected,
} = contenderSlice.actions;

export default contenderSlice.reducer;
