const parsedJudgeResponse = (arr = []) => {
  return arr.reduce(
    (acc, element) => {
      const { rounds } = element;
      acc[`round${rounds}`].push(element);
      return acc;
    },
    { round1: [], round2: [] }
  );
};

const parsedJudgeResponseToPointing = ({ round1, round2 }) => {
  const newRound1 = round1.reduce((acc, ele) => {
    if (ele.point === null) {
      acc.push(ele);
    }
    return acc;
  }, []);

  const newRound2 = round2.reduce((acc, ele) => {
    if (ele.point === null) {
      acc.push(ele);
    }
    return acc;
  }, []);

  return { round1: newRound1, round2: newRound2 };
};

const getTotalValue = (obj) => {
  if (obj === null) {
    return 0;
  }
  return Object.values(obj).reduce((a, b) => a + b, 0);
};

const filterSlotFromPoints = (point) => {
  if (point !== null) {
    const slotKeys = [
      "slot1",
      "slot2",
      "slot3",
      "slot4",
      "slot5",
      "slot6",
      "slot7",
      "slot8",
      "slot9",
      "slot10",
    ];

    const objetoFiltrado = slotKeys.reduce((obj, key) => {
      if (point[key]) {
        obj[key] = point[key];
      }
      return obj;
    }, {});

    return objetoFiltrado;
  } else {
    return 0;
  }
};

export {
  parsedJudgeResponse,
  parsedJudgeResponseToPointing,
  getTotalValue,
  filterSlotFromPoints,
};
