import React, { useState } from "react";
import "./cardCompetition.css";
import CardCompetitionModal from "../cardCompetitionModal/CardCompetitionModal";
import menu from "../../assets/hamburger_menu.svg";
import back from "../../assets/back.svg";

const CardCompetition = ({
  title,
  subtitle,
  backAction,
  photoURL,
  deductionAction,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <div className="flex items-center justify-around">
      {backAction && (
        <div>
          <img
            className="mr-2 p-3 h-full"
            src={back}
            alt="back_icon"
            onClick={backAction}
          />
        </div>
      )}
      <CardCompetitionModal
        isOpen={isOpenModal}
        onClose={setIsOpenModal}
        addDeduction={deductionAction}
        photoURL={photoURL}
      />
      <div className="flex flex-col items-center rounded-lg w-full py-2">
        <span className="card-competition__title">{title}</span>
        <span className="flex items-center gap-x-1.5 card-competition__subtitle font-bold text-lg text-white">
          <div id="circle"></div>
          {subtitle}
        </span>
      </div>
      {(photoURL || deductionAction) && (
        <img
          className="ml-2 p-3"
          src={menu}
          alt="hamburger_menu"
          onClick={() => setIsOpenModal((prevState) => !prevState)}
        />
      )}
    </div>
  );
};

export default CardCompetition;
