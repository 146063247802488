import React from "react";
import { useTranslation } from "react-i18next";
import "./modalChallenge.css";

const ModalChallenge = ({ isOpen, onCloseModal, detail }) => {
  const { t } = useTranslation();
  const { judgeName, maxPoints, userPoints, points, comment } = detail;

  const closeModal = () => {
    onCloseModal();
  };

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={handleModalClick}
        >
          <div className="flex flex-col w-3/4 text-white content-center justify-center bg-lilac-700 rounded">
            <div className="modal-challenge__judge-name p-6">{judgeName}</div>
            <div className="modal-challenge__posible flex justify-between  py-3 mx-6">
              <div>{t("POSSIBLE_SCORE")}</div>
              <div>{maxPoints}</div>
            </div>
            {points?.map((point, index) => (
              <div
                className="modal-challenge__point flex justify-between py-3 mx-6"
                key={index}
              >
                {points.length > 1 && (
                  <>
                    <div>{`${t("FINGER")} ${index + 1}`}</div>
                  </>
                )}
                {points.length <= 1 && (
                  <>
                    <div>{`${t("OBTAINED_SCORE")}`}</div>
                  </>
                )}

                <div>{point.points}</div>
              </div>
            ))}
            <div className="modal-challenge__total flex justify-between bg-lilac-200 text-white font-bold py-3 px-6">
              <div>{t("TOTAL")}</div>
              <div>{userPoints}</div>
            </div>
            {comment && (
              <div className="modal-challenge__comment mx-8 py-8">
                {comment}
              </div>
            )}
            <div className="flex flex-row-reverse">
              <button
                className="modal-challenge__button p-8"
                onClick={closeModal}
              >
                {t("CLOSE")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalChallenge;
