import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wizard from "../../../components/wizard/Wizard";
import InitialViewJudge from "../initialViewJudge/InitialViewJudge";
import CompletedProcessJudgeView from "../completedProcessJudgeView/CompletedProcessJudgeView";
import PointsView from "../pointsView/PointsView";
import SumaryView from "../sumaryView/SumaryView";
import Header from "../../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { ROUNDS } from "../../../constanst/constants";
import { startProcess } from "../../../store/slices/stepSlice";

const MainViewJudge = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState([]);
  const {
    judge: {
      successFetchingJudgeData,
      judgeDataToPointing,
      actualRound,
      isEditing,
    },
    step: { completed },
  } = useSelector((state) => state);

  const stepMaker = (roundx) => {
    const newSteps = [];
    let count = 0;
    for (let i = 0; i < roundx?.length; i++) {
      newSteps[count++] = {
        id: count,
        component: <PointsView handleNextStep={() => {}} />,
      };
      newSteps[count++] = {
        id: count,
        component: <SumaryView handleNextStep={() => {}} />,
      };
    }
    return newSteps;
  };

  useEffect(() => {
    if (isEditing) {
      setSteps([]);
      const steps = [
        {
          id: 1,
          component: <PointsView handleNextStep={() => {}} />,
        },
        {
          id: 2,
          component: <SumaryView handleNextStep={() => {}} />,
        },
      ];
      setSteps(steps);
    }
  }, [isEditing]);

  useEffect(() => {
    if (steps?.length > 0 && isEditing) {
      dispatch(startProcess({ step: 1, checking: false }));
    }
  }, [steps]);

  useEffect(() => {
    if (!successFetchingJudgeData && !completed) {
      navigate("/default-view");
    } else {
      if (actualRound !== ROUNDS[3] && !isEditing)
        setSteps(stepMaker(judgeDataToPointing[actualRound]));
    }
  }, [successFetchingJudgeData]);

  const wizardProps = {
    steps: steps,
    initialView: {
      component: <InitialViewJudge />,
    },
    completedView: {
      component: <CompletedProcessJudgeView />,
    },
  };

  if (successFetchingJudgeData) {
    return (
      <div className="flex flex-col h-full">
        <Header />
        <Wizard {...wizardProps} />
      </div>
    );
  }
};

export default MainViewJudge;
