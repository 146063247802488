import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login } from "../../service/providers/providerAuth";

const initialState = {
  isAuthenticated: false,
  token: null,
  role: null,
  userTypeId: null,
  isSingingUp: false,
  isGivingAlta: false,
  isLoginUp: false,
  isLoadingLogin: false,
  errorLogin: {
    error: false,
    message: "",
  },
};

const token = localStorage.getItem("token");

if (token) {
  initialState.isAuthenticated = true;
  initialState.token = token;
  const base64Results = token.split(".");
  const payload = JSON.parse(atob(base64Results[1]));
  initialState.role = parseInt(payload.Roles);
  initialState.userTypeId = parseInt(payload.Id);
}

export const postLogin = createAsyncThunk("auth/login", login);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => ({
      ...state,
      isAuthenticated: action.payload,
    }),
    setIsSingingUp: (state, action) => ({
      ...state,
      isSingingUp: action.payload,
    }),
    setIsGivingAlta: (state, action) => ({
      ...state,
      isGivingAlta: action.payload,
    }),
    setIsLoginUp: (state, action) => ({ ...state, isLoginUp: action.payload }),
    setToken: (state, action) => ({
      ...state,
      token: action.payload?.token,
    }),
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.isLoadingLogin = false;
      state.errorLogin = false;
      state.role = null;
      state.userTypeId = null;
      localStorage.removeItem("token");
    },
    resetErrorLogin: (state) => ({
      ...state,
      errorLogin: {
        error: false,
        message: "",
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(postLogin.pending, (state) => {
      state.isLoadingLogin = true;
      state.isAuthenticated = false;
      state.errorLogin = {
        error: false,
        message: "",
      };
    });
    builder.addCase(postLogin.fulfilled, (state, action) => {
      localStorage.setItem("token", action.payload.token);
      const base64Results = action.payload.token.split(".");
      const payload = JSON.parse(atob(base64Results[1]));
      state.token = action.payload.token;
      state.role = parseInt(payload.Roles);
      state.userTypeId = parseInt(payload.Id);
      state.isLoadingLogin = false;
      state.isAuthenticated = true;
      state.errorLogin = {
        error: false,
        message: "",
      };
    });
    builder.addCase(postLogin.rejected, (state) => {
      state.isLoadingLogin = false;
      state.isAuthenticated = false;
      state.errorLogin = {
        error: true,
        message: "Error entering credentials or selecting the event, Please try again",
      };
    });
  },
});

export const {
  setIsAuthenticated,
  setIsSingingUp,
  setIsGivingAlta,
  setIsLoginUp,
  setToken,
  logoutUser,
  resetErrorLogin,
} = authSlice.actions;

export default authSlice.reducer;
