import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/slices/authSlice";
import "./header.css";
import { useNavigate } from "react-router";
import { updateEventId } from "../../store/slices/eventSlice";
import UserMenu from "../userMenu/UserMenu";
import { ROLES } from "../../constanst/constants";
import HamburgerMenu from "../hamburgerMenu/HamburgerMenu";
import { useTranslation } from "react-i18next";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    auth: { isAuthenticated, isSingingUp, isGivingAlta, role },
  } = useSelector((state) => state);

  const userRole = t(ROLES[role]) || "";

  useEffect(() => {
    if (!isAuthenticated && (!isSingingUp || !isGivingAlta)) {
      if (!isGivingAlta) {
        if (!isSingingUp) {
          navigate("/");
        } else {
          navigate("/sign-up");
        }
      }
    }
  }, [isAuthenticated, isSingingUp, isGivingAlta]);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(updateEventId(null));
  };

  return (
    <div className="header__container flex justify-between items-center sticky top-0">
      <HamburgerMenu />
      <span className="text-2xl font-semibold">beautycomp</span>
      {isSingingUp ? (
        <a href="/login" className="mr-4">
          {t("LOGIN")}
        </a>
      ) : (
        <UserMenu handleLogout={handleLogout} role={userRole} />
      )}
    </div>
  );
};

export default Header;
