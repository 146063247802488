import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getJudgeData } from "../../service/providers/providerJudge";
import {
  parsedJudgeResponse,
  parsedJudgeResponseToPointing,
} from "../../helpers/judgeHelper";

const initialState = {
  loading: true,
  errorFetchingJudgeData: {
    error: false,
    message: "",
  },
  successFetchingJudgeData: false,
  photoURL: "",
  judgeData: {},
  judgeDataToPointing: {},
  actualRound: "",
  dataLength: 0,
  previousIndex: 0,
  currentIndex: 0,
  isEditing: false,
  dataToEditJudge: [],
};

export const fetchJudgeData = createAsyncThunk(
  "judge/fetchJudgeData",
  getJudgeData
);

const judgeDataSlice = createSlice({
  name: "judgeData",
  initialState,
  reducers: {
    getJudgeDataSuccess: (state, action) => ({
      ...state,
      loading: false,
      judgeData: action.payload,
    }),
    updateDataLength: (state, action) => ({
      ...state,
      dataLength: action.payload,
    }),
    updateEditing: (state, action) => ({
      ...state,
      isEditing: action.payload,
    }),
    goToNextIndex: (state) => ({
      ...state,
      previousIndex: state.currentIndex,
      currentIndex: state.currentIndex + 1,
    }),
    updateJudgeDataToPointing: (state, action) => ({
      ...state,
      judgeDataToPointing: action.payload,
    }),
    updateActualRound: (state, action) => ({
      ...state,
      actualRound: action.payload,
    }),
    updateDataJudge: (state, action) => ({
      ...state,
      judgeData: action.payload,
    }),
    setIsEditingJudge: (state, action) => ({
      ...state,
      isEditing: action.payload,
    }),
    updateDataToEditJudge: (state, action) => ({
      ...state,
      dataToEditJudge: [action.payload],
    }),
    setCurrentIndex: (state, action) => ({
      ...state,
      currentIndex: action.payload,
    }),
    resetJudgeDataError: (state) => ({
      ...state,
      errorFetchingJudgeData: {
        error: false,
        message: "",
      },
    }),
    resetJudgeData: (state) => ({
      ...state,
      successFetchingJudgeData: false,
      photoURL: "",
      judgeData: {},
      judgeDataToPointing: {},
      actualRound: "",
      dataLength: 0,
      previousIndex: 0,
      currentIndex: 0,
      isEditing: false,
      dataToEditJudge: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJudgeData.pending, (state) => {
      state.loading = true;
      state.successFetchingJudgeData = false;
      state.errorFetchingJudgeData = {
        error: false,
        message: "",
      };
    });
    builder.addCase(fetchJudgeData.fulfilled, (state, action) => {
      const parsedResponse = parsedJudgeResponse(
        action.payload.userChallengeCriteria
      );
      state.judgeData = parsedResponse;
      state.judgeDataToPointing = parsedJudgeResponseToPointing(parsedResponse);
      state.photoURL = action.payload.photoURL;
      state.loading = false;
      state.successFetchingJudgeData = true;
      state.errorFetchingJudgeData = {
        error: false,
        message: "",
      };
    });
    builder.addCase(fetchJudgeData.rejected, (state, action) => {
      state.loading = false;
      state.successFetchingJudgeData = false;
      state.errorFetchingJudgeData = {
        error: true,
        message: action.error.message,
      };
    });
  },
});

export const {
  getJudgeDataSuccess,
  updateDataLength,
  updateEditing,
  goToNextIndex,
  updateJudgeDataToPointing,
  updateActualRound,
  updateDataJudge,
  setIsEditingJudge,
  updateDataToEditJudge,
  setCurrentIndex,
  resetJudgeDataError,
  resetJudgeData,
} = judgeDataSlice.actions;

export default judgeDataSlice.reducer;
