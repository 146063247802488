import React from "react";
import { ErrorMessage, Field } from "formik";

const Select = ({ label, name, options = [], customClass, ...props }) => (
  <div className={`w-full ${customClass}`}>
    <label
      htmlFor={name}
      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
    >
      {label}
    </label>
    <div className="relative">
      <Field
        className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        as="select"
        id={name}
        name={name}
        {...props}
      >
        {options?.map((option) => {
          return (
            <option key={option.id} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Field>
    </div>
    <ErrorMessage name={name}>
      {(msg) => <div className="font-semibold text-red-100">{msg}</div>}
    </ErrorMessage>
  </div>
);

export default Select;
