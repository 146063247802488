import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardDivision from "../../../components/cardDivision/CardDivision";
import TotalPoints from "../../../components/totalPoints/TotalPoints";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addDataToEdit, setIsEditing } from "../../../store/slices/userSlice";
import { goToStep, startProcess } from "../../../store/slices/stepSlice";
import Spinner from "../../../components/spinner/Spinner";
import DeductionCard from "../../../components/deductionCard/DeductionCard";
import {
  addDeductionToEdit,
  deleteDeductionContender,
  fetchContenderDeduction,
  resetContenderDeduction,
  resetDeleteDeduction,
  setIsEditingDeduction,
} from "../../../store/slices/deductionSlice";

const InitialViewSupervisor = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    deduction: {
      isLoadingContenderDeduction,
      contenderDeductionData,
      isLoadingDeleteDeduction,
      successDeletingDeduction,
    },
    user: {
      contenderPoints: { round1, round2 },
    },
    qrHandler: {
      qrData: { eventId, contenderId },
    },
  } = useSelector((state) => state);

  const challengeName = round1[0].challengeName;
  const { divisionName, photoURL } = round1[0];

  useEffect(() => {
    dispatch(setIsEditing(false));
  }, []);

  useEffect(() => {
    if (successDeletingDeduction) {
      dispatch(fetchContenderDeduction({ params: { eventId, contenderId } }));
      dispatch(resetDeleteDeduction());
    }
  }, [successDeletingDeduction]);

  const onEditCriteria = (data) => {
    dispatch(addDataToEdit(data));
    dispatch(startProcess({ step: 1, checking: false }));
    dispatch(setIsEditing(true));
  };

  const onEditDeduction = (data, deductionnumber) => {
    dispatch(addDeductionToEdit(data));
    dispatch(goToStep(2));
    dispatch(setIsEditingDeduction(true));
  };

  const deductionAction = () => {
    dispatch(goToStep(2));
  };

  const onDeleteDeduccion = (data) => {
    dispatch(
      deleteDeductionContender({
        deductionnumber: data.deductionnumber,
        eventId: data.eventid,
        challengeid: data.challengeid,
        divisionid: data.divisionid,
        judgeid: data.judgeid,
        contenderid: data.contenderid,
      })
    );
  };

  const finishProccess = () => {
    dispatch(resetContenderDeduction());
    navigate("/default-view");
  };

  if (isLoadingContenderDeduction || isLoadingDeleteDeduction) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="m-2">
        <CardCompetition
          title={t("COMPETITION")}
          subtitle={challengeName}
          backAction={finishProccess}
          deductionAction={deductionAction}
          photoURL={photoURL}
        />
        <div className="my-1">
          <CardDivision title={t("DIVISION")} subtitle={divisionName} />
        </div>
        {round1.length > 0 && (
          <>
            <CardDivision theme="dark" title={t("ROUND")} subtitle={1} />
            {round1.map((round) => (
              <TotalPoints
                score={round.totalPoints}
                totalPoints={round.maxPoints}
                criterio={round.criteriaNames[i18n.language]}
                key={round.criteriaId}
                onEdit={() => onEditCriteria(round)}
                judgeName={round.judgeName}
              />
            ))}
          </>
        )}
        {round2.length > 0 && (
          <>
            <CardDivision theme="dark" title={t("ROUND")} subtitle={2} />
            {round2.map((round) => (
              <TotalPoints
                score={round.totalPoints}
                totalPoints={round.maxPoints}
                criterio={round.criteriaNames[i18n.language]}
                key={round.criteriaId}
                onEdit={() => onEditCriteria(round)}
                judgeName={round.judgeName}
              />
            ))}
          </>
        )}
        {contenderDeductionData.length > 0 && (
          <>
            {contenderDeductionData.map((deduction, index) => (
              <DeductionCard
                score={deduction.score}
                onEdit={() => onEditDeduction(deduction, index + 1)}
                onDelete={() => onDeleteDeduccion(deduction, index + 1)}
                criterio={t("DEDUCTION")}
              />
            ))}
          </>
        )}
      </div>
      <Button
        label={t("FINALIZE")}
        customClass="w-full sticky bottom-0"
        onClick={finishProccess}
      />
    </div>
  );
};

export default InitialViewSupervisor;
