import { useTranslation } from "react-i18next";
import Button from "../button/Button";

const CardCriterio = ({
  judgeName,
  criteriaName,
  userPoints,
  maxPoints,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-2 flex flex-col bg-white rounded-lg mt-2">
      <div className="flex justify-center">
        <span className="text-lilac-700 mr-1">{`${t("JUDGE_NAME")}:`}</span>
        <span className="text-lilac-700 font-bold">{judgeName}</span>
      </div>
      <div className="flex justify-center">
        <span className="text-lilac-700 mr-1">{`${t("CRITERION")}:`}</span>
        <span className="text-lilac-700 font-bold">{criteriaName}</span>
      </div>
      <div className="flex text-lilac-200 text-lg justify-center">
        <span>{maxPoints}</span>
        <span className="mx-1">/</span>
        <span className="font-bold">{userPoints}</span>
      </div>
      <div className="flex justify-center">
        <Button label={t("SEE_DETAIL")} theme="dark" onClick={handleClick} />
      </div>
    </div>
  );
};

export default CardCriterio;
