import React, { useEffect, useState } from "react";
import Textarea from "../../../components/textarea/Textarea";
import Button from "../../../components/button/Button";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { useDispatch, useSelector } from "react-redux";
import { goBack, setInitial } from "../../../store/slices/stepSlice";
import Spinner from "../../../components/spinner/Spinner";
import RangeSlider from "../../../components/rangeSlider/RangeSlider";
import {
  addDeductionData,
  fetchContenderDeduction,
  resetDeductionToEdit,
} from "../../../store/slices/deductionSlice";
import { resetDataToEdit } from "../../../store/slices/userSlice";

const DeductionView = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isPosting, setIsPosting] = useState(false);
  const {
    auth: { userTypeId },
    deduction: {
      isLoadingPostingDeduction,
      successPostingDeduction,
      isLoadingContenderDeduction,
      isEditingDeduction,
      deductionToEdit,
    },
    qrHandler: {
      qrData: { eventId, challengeId },
    },
    user: {
      contenderPoints: { round1 },
    },
  } = useSelector((state) => state);

  const { score, comment, deductionnumber } = deductionToEdit;
  const {
    divisionName,
    criteriaNames,
    challengeName,
    photoURL,
    divisionId,
    contenderId,
  } = round1[0];

  const backStep = () => {
    dispatch(setInitial());
  };

  const onSubmit = (data) => {
    dispatch(
      addDeductionData({
        deductionnumber: isEditingDeduction ? deductionnumber : -1,
        eventId: isEditingDeduction ? deductionToEdit.eventid : eventId,
        challengeid: isEditingDeduction
          ? deductionToEdit.challengeid
          : challengeId,
        divisionid: isEditingDeduction
          ? deductionToEdit.divisionid
          : divisionId,
        judgeid: userTypeId,
        contenderId: isEditingDeduction
          ? deductionToEdit.contenderid
          : contenderId,
        ...data,
      })
    );
    setIsPosting(true);
  };

  useEffect(() => {
    if (successPostingDeduction && isPosting) {
      dispatch(resetDataToEdit());
      dispatch(
        fetchContenderDeduction({
          params: {
            eventId,
            contenderId: isEditingDeduction
              ? deductionToEdit.contenderid
              : contenderId,
          },
        })
      );
      dispatch(resetDeductionToEdit());
      dispatch(setInitial());
    }
  }, [successPostingDeduction, isPosting]);

  if (isLoadingPostingDeduction || isLoadingContenderDeduction) {
    return <Spinner />;
  }

  const initialValues = {
    score: isEditingDeduction ? score : -1,
    comment: isEditingDeduction ? comment : "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => {
        return (
          <Form className="h-full flex flex-col">
            <CardCompetition
              customClass="mx-2"
              title={t("COMPETITION")}
              subtitle={
                isEditingDeduction
                  ? deductionToEdit.eventchallengename
                  : challengeName
              }
              backAction={backStep}
              photoURL={photoURL}
            />
            <div className="my-1 mx-2">
              <CardDivision
                title={t("DIVISION")}
                subtitle={
                  isEditingDeduction
                    ? deductionToEdit.divisionname
                    : divisionName
                }
              />
            </div>
            <div className="mx-2">
              <CardDivision
                theme="dark"
                title={t("CRITERION")}
                subtitle={criteriaNames[i18n.language]}
              />
              <RangeSlider
                label={t("DEDUCTION")}
                name="score"
                id="score"
                min={0}
                max={5}
                step={1}
              />
              <Textarea name="comment" id="comment" numRow={6} label={t("NOTES")} />
            </div>
            <Button
              type="submit"
              customClass="w-full mt-auto sticky bottom-0"
              label={
                isEditingDeduction ? t("DELETE_DEDUCTION") : t("APPLY_DEDUCTION")
              }
              theme="red"
              disabled={values.score < 0}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeductionView;
