import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../../../components/button/Button";
import CheckBoxGroup from "../../../components/checkboxGroup/CheckBoxGroup";
import RadioButtonGroup from "../../../components/radioButtonGroup/RadioButtonGroup";
import Textarea from "../../../components/textarea/Textarea";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import Header from "../../../components/header/Header";
import Modal from "../../../components/modal/Modal";
import { useNavigate } from "react-router-dom";
import { setFaceToFaceData } from "../../../store/slices/altaSlice";
import { parsedChallengesByDivision } from "../../../helpers/eventHelper";

const CompetitionFaceToFaceView = ({ handleNextStep, name }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    alta: {
      faceToFaceData: {
        lastFirst,
        challengesSelected,
        divisionSelected,
        primerLugar,
      },
    },
    event: {
      eventByIdData: { competitionTypes },
      onSiteChallenges,
      onSiteDivisions,
    },
    user: {
      isLoadingNewContender,
      errorSendingNewContender: { error, message },
    },
  } = useSelector((state) => state);
  const [parsedChallenges, setParsedChallenges] = useState(onSiteChallenges);

  useEffect(() => {
    if (onSiteChallenges.length === 0 && onSiteDivisions.length === 0) {
      handleNextStep();
    }
  }, []);

  const primerFields = [
    {
      id: 1,
      value: "true",
      label: t("YES"),
    },
    {
      id: 2,
      value: "false",
      label: t("NO"),
    },
  ];

  const validationSchema = Yup.object().shape({
    divisionSelected: Yup.string().required(t("REQUIRED_FIELD")),
    challengesSelected: Yup.array().required(t("REQUIRED_FIELD")),
  });

  const onSubmit = (data) => {
    dispatch(setFaceToFaceData(data));
    handleNextStep();
  };

  const handleChangeDivision = (value) => {
    const newParsedChallenges = parsedChallengesByDivision(
      competitionTypes,
      "On-site",
      value
    );
    setParsedChallenges(newParsedChallenges.parsedChallenges);
  };

  if (isLoadingNewContender) {
    return <Spinner />;
  }

  return (
    <div className="h-full flex flex-col">
      <Header />
      <Formik
        initialValues={{
          subscribe: "false",
          divisionSelected,
          challengesSelected,
          primerLugar,
          lastFirst,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isValid }) => (
          <Form className="h-full flex flex-col p-4">
            <CardCompetition
              title={t("INSCRIPTION_FORM")}
              subtitle={name}
            />
            <h2 className="font-bold mt-2">
              {t("WANT_INSCRIT_INPERSON")}
            </h2>
            <RadioButtonGroup name="subscribe" fields={primerFields} />
            <h2 className="font-bold mt-2">{`${t("IN_PERSON_DIVISION")}:`}</h2>
            <RadioButtonGroup
              name="divisionSelected"
              fields={onSiteDivisions}
              disabled={values.subscribe === "false"}
              onChange={(event) => handleChangeDivision(event)}
            />
            <CheckBoxGroup
              legend={`${t("IN_PERSON_COMPETITONS")}:`}
              fields={parsedChallenges}
              name="challengesSelected"
              disabled={values.subscribe === "false"}
              selected={values.challengesSelected}
            />
            <h2 className="font-bold mt-2">
              {t("IN_PERSON_FIRST_PLACE")}
            </h2>
            <RadioButtonGroup
              name="primerLugar"
              disabled={values.subscribe === "false"}
              fields={primerFields}
            />
            <Textarea
              name="lastFirst"
              id="lastFirst"
              disabled={values.subscribe === "false"}
              numRow={4}
              label={t("IN_PERSON_LAST_FIRST_PLACE")}
            />
            {values.subscribe === "true" ? (
              <Button
                customClass="w-full mt-auto sticky bottom-0"
                label={t("CONTINUE")}
                disabled={!isValid && values.subscribe === "true"}
                type="submit"
              />
            ) : (
              <Button
                customClass="w-full mt-auto sticky bottom-0"
                label={t("CONTINUE")}
                disabled={!isValid && values.subscribe === "true"}
                onClick={() => handleNextStep()}
                type="button"
              />
            )}
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={error}
        message={message}
        onCloseModal={() => navigate("/")}
      />
    </div>
  );
};

export default CompetitionFaceToFaceView;
