import { useEffect, useRef, useState } from "react";
import Menu from "../../assets/h-menu.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, changeUserLanguage } from "../../store/slices/lenguajeSlice";
import "./hamburgerMenu.css";

const LENGUAJES = [
  {
    id: 0,
    value: "",
    label: "Seleccione un lenguaje",
  },
  {
    id: 1,
    value: "en",
    label: "Ingles",
  },
  {
    id: 2,
    value: "es",
    label: "Español",
  },
  {
    id: 3,
    value: "pt",
    label: "Portugués",
  },
  {
    id: 4,
    value: "fr",
    label: "Francés",
  },
  {
    id: 5,
    value: "it",
    label: "Italiano",
  },
];

const HamburgerMenu = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const menuRef = useRef(null);
  const {
    lenguaje: { lenguaje },
  } = useSelector((state) => state);

  const handleMenuToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const setChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(changeLanguage(lng));
    // dispatch(changeUserLanguage({
    //   anEmail: "saenztandil@gmail.com",
    //   newLanguage: lng
    // }))
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={handleMenuToggle}
        className="flex items-center focus:outline-none mr-2"
      >
        <img src={Menu} alt="menu-hamburguesa" className="m-4" />
      </button>

      {isOpen && (
        <div className="absolute left-0 z-50 w-48 bg-lilac-700 rounded shadow-md">
          <div className="px-4 py-2">
            <div className="w-full">
              <label
                htmlFor="lenguajes"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                {t("IDIOM")}
              </label>
              <div className="relative text-black">
                <select
                  className="block select-container text-black appearance-none w-full bg-gray-100 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lenguajes"
                  name="lenguajes"
                  value={lenguaje}
                  onChange={(event) => setChangeLanguage(event.target.value)}
                >
                  {LENGUAJES?.map((option) => {
                    return (
                      <option
                        className="text-black"
                        key={option.id}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
