import React, { useEffect } from "react";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { useDispatch, useSelector } from "react-redux";
import { startProcess } from "../../../store/slices/stepSlice";
import { useTranslation } from "react-i18next";
import {
  resetEventSelected,
  setEventSelected,
} from "../../../store/slices/contenderSlice";

const InitialViewContender = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    contender: { contenderData },
  } = useSelector((state) => state);

  const { events } = contenderData;

  useEffect(() => {
    dispatch(resetEventSelected());
  }, []);

  const handleClick = (event) => {
    dispatch(startProcess({ step: 1, checking: false }));
    dispatch(setEventSelected(event));
  };

  return (
    <div className="h-full flex flex-col">
      <div className="mx-2 mb-2">
        {events?.map((event) => (
          <CardDivision
            customClass="mt-2"
            theme="dark"
            title={t("EVENT")}
            subtitle={event.eventName}
            onClick={() => handleClick(event)}
            key={event.eventId}
          />
        ))}
        {!events && (
          <div className="mt-12 text-center">{t("NO_EVENTS_MESSAGE")}</div>
        )}
      </div>
    </div>
  );
};

export default InitialViewContender;
