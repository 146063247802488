import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import Select from "../../../components/select/Select";
import Header from "../../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserByEmail } from "../../../store/slices/userSlice";
import { useTranslation } from "react-i18next";

const USER_TYPE_OPTIONS = [
  { id: 0, value: "", label: "Selecciona una opcion" },
  { id: 1, value: "concursante", label: "Concursante" },
  { id: 2, value: "fotografo", label: "Fotografo" },
  { id: 3, value: "juez", label: "Juez" },
  { id: 6, value: "supervisor", label: "Supervisor" },
];

const AccessDataView = ({
  userType = "",
  email = "",
  confirmEmail = "",
  password = "",
  confirmPassword = "",
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const {
    user: { successUserByEmail },
  } = useSelector((state) => state);

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    userType: Yup.string().required(t("VALIDATION_USER_TYPE")),
    email: Yup.string()
      .email(t("VALIDATION_EMAIL"))
      .required(t("VALIDATION_EMAIL_REQUIRED")),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], t("VALIDATION_EMAIL_NOT_MATCH"))
      .required(t("VALIDATION_EMAIL_CONFIRM")),
    password: Yup.string()
      .min(6, t("VALIDATION_PASSWORD_MIN"))
      .required(t("VALIDATION_PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("VALIDATION_PASSWORD_NOT_MATCH"))
      .required(t("VALIDATION_PASSWORD_CONFIRM")),
  });

  const validateUserEmail = (email) => {
    dispatch(fetchUserByEmail(email));
  };

  return (
    <div className="flex flex-col h-full">
      <Header />
      <Formik
        initialValues={{
          userType,
          email,
          confirmEmail,
          password,
          confirmPassword,
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => onSubmit(data)}
      >
        {({ values, isValid }) => (
          <Form className="h-full flex flex-col">
            <div className="h-full flex flex-col m-2">
              <h1>{t("ACCESS_DATA")}</h1>
              <Select
                label={t("USER_TYPE")}
                name="userType"
                id="userType"
                options={USER_TYPE_OPTIONS}
                customClass="mt-3"
              />
              <Input
                type="email"
                name="email"
                id="email"
                label={t("EMAIL")}
                onBlur={() => validateUserEmail(values.email)}
              />
              {successUserByEmail && values.email.length > 0 && (
                <div className="mb-2">
                  {t("ERROR_EMAIL_EXIST")}
                  <a className="font-bold text-sky-600 ml-2" href="/login">
                    {t("LOGIN")}
                  </a>
                </div>
              )}
              <Input
                type="email"
                name="confirmEmail"
                id="confirmEmail"
                label={t("CONFIRM_EMAIL_LABEL")}
              />
              <Input
                type="password"
                name="password"
                id="password"
                label={t("PASSWORD")}
              />
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                label={t("CONFIRM_PASSWORD_LABEL")}
              />
            </div>
            <Button
              disabled={!isValid || successUserByEmail}
              type="submit"
              customClass="w-full fixed bottom-0"
              label={t("CONTINUE")}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccessDataView;
