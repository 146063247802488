const parsedContenderPointsResponse = (arr = []) => {
  return arr.reduce(
    (acc, element) => {
      const { round } = element;
      acc[`round${round}`].push(element);
      return acc;
    },
    { round1: [], round2: [] }
  );
};

export { parsedContenderPointsResponse };
