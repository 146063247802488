import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import logo from "../../assets/beauty-icon.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { postLogin, resetErrorLogin } from "../../store/slices/authSlice";
import Spinner from "../../components/spinner/Spinner";
import { fetchEventPoints, updateEventId } from "../../store/slices/eventSlice";
import Select from "../../components/select/Select";
import { getEventCod } from "../../service/bodyParsers/eventParser";
import { useNavigate } from "react-router";
import Modal from "../../components/modal/Modal";
import { useTranslation } from "react-i18next";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();
  const {
    auth: {
      isAuthenticated,
      errorLogin: { error, message },
      role,
      isLoadingLogin,
    },
    event: {
      isLoadingEvent,
      eventParsed,
      successFetchingEventdata,
      hasFetchedEvent,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!hasFetchedEvent) {
      dispatch(fetchEventPoints());
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (role === 1) {
        navigate("/qr-handler");
      } else {
        navigate("/default-view");
      }
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (error) {
      setIsOpenModal(true);
    }
  }, [error]);

  const onSubmit = (data) => {
    const body = {
      email: data.email,
      password: data.password,
      eventId: getEventCod(eventParsed, data.eventId),
    };
    dispatch(updateEventId(getEventCod(eventParsed, data.eventId)));
    dispatch(postLogin(body));
  };

  const onCloseModal = () => {
    setIsOpenModal((prevState) => !prevState);
    dispatch(resetErrorLogin());
  };

  if (isLoadingEvent || !successFetchingEventdata || isLoadingLogin) {
    return <Spinner />;
  }

  return (
    <div className="h-full">
      <Modal
        isOpen={isOpenModal}
        onCloseModal={onCloseModal}
        message={message}
      />
      <Formik
        initialValues={{
          email: "",
          password: "",
          eventId: "",
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form className="flex flex-col h-full">
            <div className="flex flex-col h-full m-2">
              <img
                src={logo}
                alt="logo"
                className="h-48 w-64 ml-auto mr-auto mt-20 mb-8"
              />
              <Input
                name="email"
                id="email"
                type="email"
                label={t("EMAIL")}
              />
              <Input
                name="password"
                id="password"
                type="password"
                label={t("PASSWORD")}
              />
              <Select
                label={t("EVENT")}
                name="eventId"
                id="eventId"
                options={eventParsed}
                customClass="mb-3"
              />
              <a href="/forget-password">{t("FORGOT_PASSWORD")}</a>
            </div>
            <Button
              type="submit"
              customClass="w-full mt-auto fixed bottom-0"
              label={t("LOGIN")}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
