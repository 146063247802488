import React, { useEffect, useState } from "react";
import Checkbox from "../checkBox/Checkbox";

const CheckBoxGroup = ({
  legend,
  description,
  fields = [],
  name,
  selected,
  disabled,
}) => {
  const [disables, setDisables] = useState([]);
  const [prevSelected, setPrevSelected] = useState([]);

  useEffect(() => {
    // console.log({ selected });
    if (selected.length > 0) {
      // console.log({ prevSelected });

      const filteredFields = fields.filter((field) =>
        selected.includes(field.value)
      );
      // console.log({ filteredFields });
      filteredFields.map((field) => {
        if (field.disables) {
          const aux = [...disables];
          // console.log(disables);
          // console.log("field.disables", field.disables);
          field.disables.map((disableField) => {
            // console.log("includesSelected", !disables.includes(disableField));
            if (!disables.includes(disableField)) {
              aux.push(disableField);
            }
          });
          // const includesSelected = disables.includes(field.disables);
          // console.log({ includesSelected });
          // if (!includesSelected) {
          //   aux.push(...field.disables);
          // }
          // console.log({ aux });
          setDisables(aux);
        }
      });
      // if (prevSelected.length > 0) {
      // const diff = selected.filter(
      //   (elemento) => prevSelected.indexOf(elemento) === -1
      // );
      const diff = selected.filter((ele) => !prevSelected.includes(ele)); //TODO: hay que hacer la diferencia para los dos casos cuando prevSelected es menor que selected y cuando es al reves porque sino sirve esto solo cuando seleccionas uno y no para cuando destildas uno.
      // console.log({ diff });
      // }
      // const elementToDelete = filteredFields.filter((field) =>
      //   selected.includes(field.value)
      // );
      setPrevSelected(selected);
    } else {
      setDisables([]);
    }
  }, [selected]);

  return (
    <div role="group" aria-labelledby="checkbox-group">
      <h2 className="font-bold">{legend}</h2>
      <span className="text-sm font-light">{description}</span>
      <div className="ml-2 flex flex-col mt-2">
        {fields?.map((field) => (
          <div className="flex flex-col" key={field.id}>
            <Checkbox
              name={name}
              label={field.label}
              value={field.value}
              comment={field.comment}
              disabled={disables.some((ele) => ele === field.id) || disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckBoxGroup;
