import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startProcess } from "../../../store/slices/stepSlice";
import AccessDataView from "../../commons/accessDataView/AccessDataView";
import { setAccessData } from "../../../store/slices/signUpSlice";

const AccessDataSignUpView = () => {
  const dispatch = useDispatch();
  const {
    signUp: { accessData },
  } = useSelector((state) => state);

  const { userType, email, confirmEmail, password, confirmPassword } =
    accessData;

  const onSubmit = (data) => {
    dispatch(setAccessData(data));
    dispatch(startProcess({ step: 1, checking: false }));
  };

  return (
    <AccessDataView
      userType={userType}
      email={email}
      confirmEmail={confirmEmail}
      password={password}
      confirmPassword={confirmPassword}
      onSubmit={onSubmit}
    />
  );
};

export default AccessDataSignUpView;
