import React, { useEffect } from "react";
import Wizard from "../../../components/wizard/Wizard";
import AccessDataAltaView from "../accesDataView/AccesDataAltaView";
import BillingViewAlta from "../billingViewAlta/BillingViewAlta";
import CompetitionFaceToFaceView from "../competitionFaceToFaceView/CompetitionFaceToFaceView";
import CompetitionNoFaceToFaceView from "../competitionNoFaceToFaceView/CompetitionNoFaceToFaceView";
import CompletedProcessAltaView from "../completedProcessAltaView/CompletedProcessAltaView";
import PersonalDataAltaView from "../personalDataView/PersonalDataAltaView";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventById } from "../../../store/slices/eventSlice";
import Spinner from "../../../components/spinner/Spinner";
import { useSearchParams } from "react-router-dom";
import { getFromSearchParams } from "../../../helpers/urlHelper";
import { setIsGivingAlta } from "../../../store/slices/authSlice";
import CompetitionDigitalView from "../competitionDigitalView/CompetitionDigitalView";
import CompetitionVirtualView from "../competitionVirtualView/CompetitionVirtualView";

const MainViewAlta = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const eventId = getFromSearchParams({ searchParams, key: "eventId" }) || "";
  const {
    auth: { isGivingAlta },
    event: {
      isLoadingEventById,
      hasFetchedEventById,
      eventByIdData: { name },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(setIsGivingAlta(true));
  }, []);

  useEffect(() => {
    if (!hasFetchedEventById && eventId) {
      dispatch(fetchEventById(eventId));
    }
  }, [eventId]);

  const wizardProps = {
    steps: [
      {
        id: 1,
        component: <PersonalDataAltaView handleNextStep={() => {}} />,
      },
      {
        id: 2,
        component: (
          <CompetitionFaceToFaceView handleNextStep={() => {}} name={name} />
        ),
      },
      {
        id: 3,
        component: (
          <CompetitionVirtualView handleNextStep={() => {}} name={name} />
        ),
      },
      {
        id: 4,
        component: (
          <CompetitionDigitalView handleNextStep={() => {}} name={name} />
        ),
      },
      {
        id: 5,
        component: (
          <CompetitionNoFaceToFaceView handleNextStep={() => {}} name={name} />
        ),
      },
      {
        id: 6,
        component: <BillingViewAlta handleNextStep={() => {}} name={name} />,
      },
    ],
    initialView: {
      component: <AccessDataAltaView />,
    },
    completedView: {
      component: <CompletedProcessAltaView name={name} />,
    },
  };

  if (isLoadingEventById) {
    return <Spinner />;
  }

  return (
    <div className="h-full">
      {isGivingAlta ? <Wizard {...wizardProps} /> : <Spinner />}
    </div>
  );
};

export default MainViewAlta;
