import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import {
  postUploadPhoto,
  resetErrorUploadingPhoto,
} from "../../../store/slices/photographerSlice";
import { setIsCompleted } from "../../../store/slices/stepSlice";

import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Modal from "../../../components/modal/Modal";

const UploadPhotoView = () => {
  const {
    photographer: {
      isloadingPhotoUpload,
      successUploadingPhoto,
      errorUploadingPhoto: { error, message },
    },
    qrHandler: {
      qrData: { eventId, challengeId, contenderId, divisionId },
    },
    auth: { userTypeId },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (successUploadingPhoto) {
      dispatch(setIsCompleted(true));
    }
  }, [successUploadingPhoto]);

  useEffect(() => {
    if (error) {
      setIsOpenModal(true);
    }
  }, [error]);

  const onCloseModal = () => {
    setIsOpenModal((prevState) => !prevState);
    dispatch(resetErrorUploadingPhoto());
  };

  if (isloadingPhotoUpload) {
    return <Spinner />;
  }

  function handleTakePhoto(dataUri) {
    const body = {
      eventId: eventId,
      challengeId: challengeId,
      contenderId: contenderId,
      divisionId: divisionId,
      photographerId: userTypeId,
      photo64string: dataUri,
    };
    dispatch(postUploadPhoto(body));
  }

  function handleTakePhotoAnimationDone(dataUri) {
    console.log("handleTakePhotoAnimationDone", dataUri);
  }

  function handleCameraError(error) {
    console.log("handleCameraError", error);
  }

  function handleCameraStart(stream) {
    console.log("handleCameraStart");
  }

  function handleCameraStop() {
    console.log("handleCameraStop");
  }
  return (
    <div className="flex flex-col h-full">
      <Modal
        isOpen={isOpenModal}
        onCloseModal={onCloseModal}
        message={message}
      />
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
        onTakePhotoAnimationDone={(dataUri) => {
          handleTakePhotoAnimationDone(dataUri);
        }}
        onCameraError={(error) => {
          handleCameraError(error);
        }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{ width: 640, height: 480 }}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={1}
        isMaxResolution={false}
        isImageMirror={false}
        isSilentMode={false}
        isDisplayStartCameraError={true}
        isFullscreen={false}
        sizeFactor={1}
        onCameraStart={(stream) => {
          handleCameraStart(stream);
        }}
        onCameraStop={() => {
          handleCameraStop();
        }}
      />
    </div>
  );
};

export default UploadPhotoView;
