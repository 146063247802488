import React, { useEffect } from "react";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCompetitionSelected,
  setCompetitionSelected,
} from "../../../store/slices/contenderSlice";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { setInitial } from "../../../store/slices/stepSlice";
import { useTranslation } from "react-i18next";

const CompetitionViewContender = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    contender: { eventSelected },
  } = useSelector((state) => state);

  const { userCompetitions, pointsPublished, userCups, userGroups } =
    eventSelected;

  useEffect(() => {
    dispatch(resetCompetitionSelected());
  }, []);

  const handleClick = (challenge) => {
    if (pointsPublished && !!challenge.division[0].userPoints) {
      dispatch(setCompetitionSelected(challenge));
      handleNextStep();
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="m-2">
        <CardCompetition
          title={t("EVENT")}
          subtitle={eventSelected.eventName}
          backAction={() => dispatch(setInitial())}
        />
        {userCompetitions.map((competition) => {
          const { competitionName, competitionId, challenges } = competition;
          return (
            <div key={competitionId}>
              <CardDivision
                customClass="mt-2"
                title= {t("COMPETITION_TYPE")}
                subtitle={competitionName}
              />
              {challenges.map((challenge) => (
                <div className="mt-2" key={challenge.challengeId}>
                  <CardDivision
                    customClass="mt-2"
                    theme="dark"
                    title={challenge.division[0].divisionName}
                    subtitle={challenge.eventChallengeName}
                    twoColumns={pointsPublished}
                    disabled={!challenge.division[0].userPoints}
                    secondTitle={t("POSITION")}
                    secondSubtitle={challenge.division[0].userPosition}
                    onClick={() => handleClick(challenge)}
                  />
                </div>
              ))}
            </div>
          );
        })}
        {userCups.length > 0 && (
          <>
            <CardDivision customClass="mt-2" title={t("CUPS")} />
            {userCups.map((cup, index) => (
              <CardDivision
                key={index}
                customClass="mt-2"
                theme="dark"
                subtitle={cup.cupName}
                twoColumns={pointsPublished}
                secondTitle={t("POINTS")}
                secondSubtitle={cup.totalPoints}
                thirdTitle={t("POSITION")}
                thirdSubtitle={cup.position}
              />
            ))}
          </>
        )}
        {userGroups.length > 0 && (
          <>
            <CardDivision customClass="mt-2" title={t("GROUPS")} />
              <CardDivision
                customClass="mt-2"
                theme="dark"
                subtitle={userGroups[0].groupName}
                twoColumns={pointsPublished}
                secondTitle={t("POINTS")}
                secondSubtitle={userGroups[0].totalPoints}
                thirdTitle={t("POSITION")}
                thirdSubtitle={userGroups[0].groupPosition}
              />
          </>
        )}
      </div>
    </div>
  );
};

export default CompetitionViewContender;
