import React from "react";
import { useField } from "formik";
import "./rangeSlider.css";

const RangeSlider = ({ label, min, max, step, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="p-4 my-1 range-slider__container">
      <label
        htmlFor="steps-range"
        className="range-slider__label flex justify-between text-sm font-medium text-gray-900"
      >
        <span className="text-lg font-bold">{label}</span>
        <span className="text-lg font-bold">
          {field.value >= 0 ? field.value : ""}
        </span>
      </label>
      <input
        className="range-slider__input"
        value={-step}
        {...field}
        {...props}
        min={min - step}
        max={max}
        step={step}
        type="range"
      />
      <div className="flex justify-between">
        <span className="text-sm text-black">N/A</span>
        {step === .5 && max === 1 && (
          <>
            <span className="text-sm text-black mr-2">0</span>
            <span className="text-sm text-black mr-2">0.5</span>
          </>
        )}
        {step === .5 && max === 2 && (
          <>
            <span className="text-sm text-black mr-2">0</span>
            <span className="text-sm text-black mr-2">0.5</span>
            <span className="text-sm text-black mr-2">1</span>
            <span className="text-sm text-black mr-2">1.5</span>
          </>
        )}
        {step === 1 && max === 1 && (
          <>
            <span className="text-sm text-black mr-2">0</span>
          </>
        )}
        {step === 1 && max === 2 && (
          <>
            <span className="text-sm text-black mr-2">0</span>
            <span className="text-sm text-black mr-2">1</span>
          </>
        )}
        {step === 1 && max === 3 && (
          <>
            <span className="text-sm text-black mr-2">0</span>
            <span className="text-sm text-black mr-2">1</span>
            <span className="text-sm text-black mr-2">2</span>
          </>
        )}
        <span className="text-sm text-black">{max}</span>
      </div>
      {meta.error && meta.touched && <div>{meta.error}</div>}
    </div>
  );
};

export default RangeSlider;
