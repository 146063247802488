import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import logo from "../../../assets/beauty-icon.jpeg";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import Header from "../../../components/header/Header";
import { setInitial } from "../../../store/slices/stepSlice";
import { resetSignUpData } from "../../../store/slices/signUpSlice";
import { resetUserNewContenderData } from "../../../store/slices/userSlice";

const CompletedProcessSignUpView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user: {
      isLoadingNewContender,
      successSendingNewContender,
      errorSendingNewContender: { error },
    },
  } = useSelector((state) => state);

  const onClick = () => {
    navigate("/");
    dispatch(setInitial());
    dispatch(resetSignUpData());
    dispatch(resetUserNewContenderData());
  };

  if (isLoadingNewContender) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col text-center">
      <Header />
      <div>
        <img
          src={logo}
          alt="logo"
          className="h-40 w-56 ml-auto mr-auto mt-10"
        />
        <h1 className="text-lg font-bold mt-2 mb-14">{t("USER_REGISTRATION")}</h1>
      </div>
      {successSendingNewContender && (
        <div>
          <h2 className="text-2xl font-bold">{t("CONGRATULATIONS")}</h2>
          <p className="mb-4">{t("USER_REGISTRATION_SUCCESS")}</p>
          <p className="px-4">
            {t("USER_REGISTRATION_SUCCESS_MSG")}
          </p>
        </div>
      )}
      {error && (
        <div>
          <h2 className="text-2xl font-bold">{t("USER_REGISTRATION_FAIL")}</h2>
          <p className="px-4">
            {t("USER_REGISTRATION_FAIL_MSG")}
          </p>
        </div>
      )}
      <Button
        customClass="w-full mt-auto fixed bottom-0"
        label={t("FINALIZE")}
        onClick={onClick}
      />
    </div>
  );
};

export default CompletedProcessSignUpView;
