import apiClient from "../apiClient";

const getEventData = async () => {
  const response = await apiClient.get("Events/GetEvents");
  return response.data;
};

const eventDataById = async (id) => {
  const response = await apiClient.get(`Events/${id}`);
  return response.data;
};

export { getEventData, eventDataById };
