import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendPoints } from "../../service/providers/providerJudge";

const initialState = {
  actualPoints: {},
  totalPoints: 0,
  successSendingPoints: false,
  isLoadingSendPoints: false,
  errorSendingPoints: false,
};

export const postSendPoints = createAsyncThunk("points/sendPoints", sendPoints);

const pointsSlice = createSlice({
  name: "points",
  initialState,
  reducers: {
    updateActualPoints: (state, action) => ({
      ...state,
      actualPoints: action.payload,
    }),
    resetActualPoints: (state) => ({
      ...state,
      actualPoints: {},
    }),
    updateTotalPoints: (state, action) => ({
      ...state,
      totalPoints: action.payload,
    }),
    resetTotalPoints: (state) => ({
      ...state,
      totalPoints: 0,
    }),
    setSuccessSendingPoints: (state, action) => ({
      ...state,
      successSendingPoints: action.payload,
    }),
    resetPoints: (state) => ({
      ...state,
      actualPoints: {},
      totalPoints: 0,
      successSendingPoints: false,
    }),
  },
  extraReducers: {
    [postSendPoints.pending]: (state) => ({
      ...state,
      isLoadingSendPoints: true,
      errorSendingPoints: null,
    }),
    [postSendPoints.fulfilled]: (state) => ({
      ...state,
      isLoadingSendPoints: false,
      successSendingPoints: true,
      actualPoints: {},
    }),
    [postSendPoints.rejected]: (state, action) => ({
      ...state,
      isLoadingSendPoints: false,
      errorSendingPoints: action.error.message,
    }),
  },
});

export const {
  updateActualPoints,
  resetActualPoints,
  updateTotalPoints,
  resetTotalPoints,
  setSuccessSendingPoints,
  resetPoints,
} = pointsSlice.actions;

export default pointsSlice.reducer;
