import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocTypes } from "../../service/providers/providerDocTypes";
import { parsedOptions } from "../../helpers/commonHelpers";

const initialState = {
  isLoadingDocTypes: false,
  docTypes: [],
  errorFetchingDocTypes: {
    error: false,
    message: "",
  },
  successFetchingDocTypes: false,
};

export const fetchDocTypes = createAsyncThunk(
  "docTypes/fetchDocTypes",
  getDocTypes
);

const docTypesSlice = createSlice({
  name: "docType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocTypes.pending, (state) => {
      state.isLoadingDocTypes = true;
      state.docTypes = [];
      state.errorFetchingDocTypes = {
        error: false,
        message: "",
      };
      state.successFetchingDocTypes = false;
    });
    builder.addCase(fetchDocTypes.fulfilled, (state, action) => {
      state.isLoadingDocTypes = false;
      state.docTypes = parsedOptions(action.payload);
      state.errorFetchingDocTypes = {
        error: false,
        message: "",
      };
      state.successFetchingDocTypes = true;
    });
    builder.addCase(fetchDocTypes.rejected, (state, action) => {
      state.isLoadingDocTypes = false;
      state.docTypes = [];
      state.errorFetchingDocTypes = {
        error: true,
        message: action.error.message,
      };
      state.successFetchingDocTypes = false;
    });
  },
});

export default docTypesSlice.reducer;
