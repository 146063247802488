import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  goBack,
  goToNextStep,
  goToPreviousStep,
  setInitial,
  setIsChecking,
} from "../../store/slices/stepSlice";
import StepContainer from "../stepContainer/StepContainer";

const renderView = (view, newProps = {}) => {
  const { component, ...props } = view;

  return React.cloneElement(component, { ...props, ...newProps });
};

const Wizard = ({ steps, initialView, completedView }) => {
  const dispatch = useDispatch();
  const {
    step: {
      // previousStep,
      currentStep,
      checking,
      completed,
      initial,
    },
  } = useSelector((state) => state);

  const handleNextStep = () => {
    if (checking && currentStep > 1) {
      dispatch(goToPreviousStep());
    }

    dispatch(goToNextStep());
  };

  const handleGoBack = () => {
    if (currentStep > 1) {
      dispatch(goBack());
    }
    if (currentStep === 1) {
      dispatch(setInitial());
    }

    dispatch(setIsChecking(false));
  };

  const renderStep = () => {
    const step = steps?.find((step) => step.id === currentStep);

    return (
      <>
        {/* <Header /> */}
        <StepContainer
          step={step}
          numberOfSteps={steps.length}
          onGoBack={handleGoBack}
        >
          {renderView(step, { handleNextStep })}
        </StepContainer>
      </>
    );
  };

  const renderContent = () => {
    if (completed) {
      return renderView(completedView);
    }
    if (initial) {
      return renderView(initialView, { handleNextStep });
    }
    return renderStep();
  };

  return <div className="wizard__container h-full">{renderContent()}</div>;
};

export default Wizard;
