import React from "react";
import RangeSlider from "../../../components/rangeSlider/RangeSlider";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import CardDivision from "../../../components/cardDivision/CardDivision";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { useDispatch, useSelector } from "react-redux";
import { HANDS } from "../../../constanst/constants";
import {
  updateActualPoints,
  updateTotalPoints,
} from "../../../store/slices/pointsSlice";
import TotalCard from "../../../components/totalCard/TotalCard";
import { filterSlotFromPoints } from "../../../helpers/judgeHelper";

const PointsView = ({ handleNextStep }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    judge: {
      photoURL,
      currentIndex,
      judgeDataToPointing,
      actualRound,
      isEditing,
    },
  } = useSelector((state) => state);

  const round = judgeDataToPointing[actualRound];
  const {
    jugdementCriteriaNames,
    hands,
    challengeName,
    maxscore,
    slotstep,
    slotcant,
    divisionName,
    point,
  } = round[currentIndex];

  const MAX = maxscore / slotcant;
  const numbOfFingers =
    slotcant === 1
      ? [{ name: `General`, id: 0 }]
      : Array(slotcant)
          .fill()
          .map((_, i) => ({ name: `${t("FINGER")} ${i + 1}`, id: i }));

  const initialValues = [...Array(slotcant).keys()].reduce((acc, curr) => {
    const key = `slot${curr + 1}`;
    acc[key] = -slotstep;
    return acc;
  }, {});

  const getTotalValue = (obj) =>
    Object.values(obj)
      .filter((num) => num >= 0)
      .reduce((a, b) => a + b, 0);

  const onSubmit = (data) => {
    dispatch(updateActualPoints(data));
    dispatch(updateTotalPoints(getTotalValue(data)));
    handleNextStep();
  };

  return (
    <Formik
      initialValues={!isEditing ? initialValues : filterSlotFromPoints(point)}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form className="h-full flex flex-col">
            <div className="m-2 rounded">
              <CardCompetition
                title={t("COMPETITION")}
                subtitle={challengeName}
                photoURL={photoURL}
              />
              <div className="flex gap-x-1.5 my-1">
                <CardDivision title={t("DIVISION")} subtitle={divisionName} />
                <CardDivision title={t("HAND")} subtitle={t(HANDS[hands])} />
              </div>
              <CardDivision
                theme="dark"
                title={t("CRITERION")}
                subtitle={jugdementCriteriaNames[i18n.language]}
              />
            </div>
            <div className="m-2">
              {numbOfFingers.map((finger, i) => {
                return (
                  <RangeSlider
                    label={finger.name}
                    name={`slot${i + 1}`}
                    id={`slot${i + 1}`}
                    min={0}
                    max={MAX}
                    step={slotstep}
                    key={finger.id}
                  />
                );
              })}
            </div>
            <div className="flex justify-center my-6">
              <TotalCard total={getTotalValue(values)} />
            </div>
            <Button
              type="submit"
              customClass="w-full mt-auto sticky bottom-0"
              label={t("CONTINUE")}
              disabled={Object.values(values).some((ele) => ele < 0)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PointsView;
