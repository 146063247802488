import React from "react";
import "./spinner.css";

const Spinner = () => (
  <div className="spinner__container">
    <div className="spinner" />
  </div>
);

export default Spinner;
