import React, { useEffect } from "react";
import PersonalDataView from "../../commons/personalDataView/PersonalDataView";
import { setAltaPersonalDataSignUp } from "../../../store/slices/altaSlice";
import { sendNewContender } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const PersonalDataAltaView = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const {
    alta: {
      accessData: {
        // userType, //TODO: falta mandar este parametro
        email,
        password,
      },
      personalData: {
        firstName,
        lastName,
        address,
        city,
        documentType,
        documentNumber,
        phone,
        country,
      },
    },
    event: {
      eventByIdData: { name },
    },
    user: { successUserByEmail },
  } = useSelector((state) => state);

  useEffect(() => {
    if (successUserByEmail) {
      handleNextStep();
    }
  }, []);

  const onSubmit = ({
    lastName,
    firstName,
    documentNumber,
    documentType,
    address,
    phone,
    city,
  }) => {
    const body = {
      mail: email,
      pass: password,
      lastname: lastName,
      firstname: firstName,
      docnumber: documentNumber.toString(),
      docid: documentType,
      street: address,
      phone: phone.toString(),
      cityName: city,
    };
    dispatch(
      setAltaPersonalDataSignUp({
        lastname: lastName,
        firstname: firstName,
        docnumber: documentNumber.toString(),
        docid: documentType,
        street: address,
        phone: phone.toString(),
        cityid: city,
      })
    );
    dispatch(sendNewContender(body));
    handleNextStep();
  };

  return (
    <PersonalDataView
      firstName={firstName}
      lastName={lastName}
      address={address}
      city={city}
      documentType={documentType}
      documentNumber={documentNumber}
      phone={phone}
      country={country}
      title={name}
      subtitle="beautycomp"
      onSubmit={onSubmit}
    />
  );
};

export default PersonalDataAltaView;
