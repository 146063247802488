import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getContenderChallenges,
  uploadPhotoBase64,
} from "../../service/providers/providerPhotographer";

const initialState = {
  isloadingPhotoUpload: false,
  successUploadingPhoto: false,
  errorUploadingPhoto: {
    error: false,
    message: "",
  },
  urlDownloadPhoto: "",
  isLoadingContenderChallengesData: false,
  contenderChallengesData: {},
  successFetchingContenderChallengesData: false,
  errorFetchingContenderChallengesData: {
    error: false,
    message: "",
  },
};

export const postUploadPhoto = createAsyncThunk(
  "photographer/uploadPhoto",
  uploadPhotoBase64
);

export const getContenderChallengesData = createAsyncThunk(
  "photographer/getContenderChallendesData",
  getContenderChallenges
);

const photographerSlice = createSlice({
  name: "photographer",
  initialState,
  reducers: {
    updateUrlPhoto: (state, action) => {
      state.urlDownloadPhoto = action.payload;
    },
    resetContenderChallengesError: (state) => ({
      ...state,
      errorFetchingContenderChallengesData: {
        error: false,
        message: "",
      },
    }),
    resetUploadedPhoto: (state) => ({
      ...state,
      isloadingPhotoUpload: false,
      successUploadingPhoto: false,
      errorUploadingPhoto: false,
      urlDownloadPhoto: "",
    }),
    resetErrorUploadingPhoto: (state) => ({
      ...state,
      errorUploadingPhoto: {
        error: false,
        message: "",
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(postUploadPhoto.pending, (state) => {
      state.isloadingPhotoUpload = true;
      state.errorUploadingPhoto = {
        error: false,
        message: "",
      };
    });
    builder.addCase(postUploadPhoto.fulfilled, (state, action) => {
      state.isloadingPhotoUpload = false;
      state.errorUploadingPhoto = {
        error: false,
        message: "",
      };
      state.urlDownloadPhoto = action.payload;
      state.successUploadingPhoto = true;
    });
    builder.addCase(postUploadPhoto.rejected, (state, action) => {
      state.isloadingPhotoUpload = false;
      state.errorUploadingPhoto = {
        error: true,
        message: "An error occurred while uploading the photo, please try again.",
      };
      state.hasFetchedEvent = false;
      state.successUploadingPhoto = false;
    });
    builder.addCase(getContenderChallengesData.pending, (state) => {
      state.isLoadingContenderChallengesData = true;
      state.errorFetchingContenderChallengesData = {
        error: false,
        message: "",
      };
    });
    builder.addCase(getContenderChallengesData.fulfilled, (state, action) => {
      state.isLoadingContenderChallengesData = false;
      state.errorFetchingContenderChallengesData = {
        error: false,
        message: "",
      };
      state.contenderChallengesData = action.payload;
      state.successFetchingContenderChallengesData = true;
    });
    builder.addCase(getContenderChallengesData.rejected, (state, action) => {
      state.isLoadingContenderChallengesData = false;
      state.successFetchingContenderChallengesData = false;
      state.errorFetchingContenderChallengesData = {
        error: true,
        message: action.error.message,
      };
    });
  },
});

export const {
  updateUrlPhoto,
  resetContenderChallengesError,
  resetUploadedPhoto,
  resetErrorUploadingPhoto,
} = photographerSlice.actions;

export default photographerSlice.reducer;
