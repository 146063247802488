import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button/Button";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { startProcess } from "../../../store/slices/stepSlice";
import TotalPoints from "../../../components/totalPoints/TotalPoints";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InitialViewPhotographer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    photographer: {
      contenderChallengesData: {
        challengeName,
        totalContendersThisChallenge,
        totalPhotosInThisChallenge,
        photoURL,
      },
    },
  } = useSelector((state) => state);

  const NextStep = () => {
    dispatch(startProcess({ step: 1, checking: false }));
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mx-2">
        <CardCompetition
          title={t("COMPETITION")}
          subtitle={challengeName}
          backAction={() => navigate("/default-view")}
          photoURL={photoURL}
        />
        <TotalPoints
          score={totalContendersThisChallenge}
          totalPoints={totalPhotosInThisChallenge}
          criterio={t("PHOTOGRAPHER_TOTAL_POINTS")}
        />
        {photoURL && (
          <img
            style={{ width: "100%", height: "auto" }}
            src={photoURL}
            alt={challengeName + "Photo"}
          />
        )}
      </div>
      <Button
        type="button"
        onClick={NextStep}
        customClass="w-full mt-auto sticky bottom-0"
        label={!photoURL ? t("UPDATE_PHOTO") : t("UPLOAD_PHOTO")}
      />
    </div>
  );
};

export default InitialViewPhotographer;
