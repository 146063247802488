import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previousStep: 0,
  currentStep: 0,
  checking: false,
  completed: false,
  initial: true,
};

export const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    goToNextStep: (state) => ({
      ...state,
      previousStep: state.currentStep,
      currentStep: state.currentStep + 1,
    }),
    goBack: (state) => ({
      ...state,
      currentStep: state.currentStep - 1,
      previousStep: state.currentStep,
    }),
    goToPreviousStep: (state) => ({
      ...state,
      currentStep: state.previousStep,
      previousStep: state.currentStep,
    }),
    goToStep: (state, action) => ({
      ...state,
      initial: false,
      currentStep: action.payload,
      previousStep: state.currentStep,
    }),
    setIsChecking: (state, action) => ({ ...state, checking: action.payload }),
    setIsCompleted: (state, action) => ({
      ...state,
      completed: action.payload,
    }),
    startProcess: (state, action) => ({
      ...state,
      initial: false,
      checking: action.payload?.checking,
      currentStep: action.payload?.step,
      previousStep: action.payload?.step,
    }),
    setInitial: (state) => ({
      ...state,
      initial: true,
      checking: false,
      completed: false,
      currentStep: 0,
      previousStep: 0,
    }),
    resetForComplete: (state) => ({
      ...state,
      checking: false,
      completed: false,
      currentStep: 0,
      previousStep: 0,
    }),
  },
});

export const {
  goToNextStep,
  goBack,
  goToPreviousStep,
  goToStep,
  setIsChecking,
  setIsCompleted,
  startProcess,
  setInitial,
  resetForComplete,
} = stepSlice.actions;

export default stepSlice.reducer;
