import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAlta } from "../../service/providers/providerAlta";

const initialState = {
  accessData: {
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  },
  personalData: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    documentType: "",
    documentNumber: "",
    phone: "",
    country: "",
  },
  faceToFaceData: {
    lastFirst: "",
    challengesSelected: [],
    divisionSelected: "",
    primerLugar: false,
  },
  noFaceToFaceData: {
    challengesSelected: [],
    divisionSelected: "",
    primerLugar: false,
    lastFirst: "",
  },
  virtual: {
    challengesSelected: [],
    divisionSelected: [],
  },
  digital: {
    challengesSelected: [],
    divisionSelected: [],
  },
  isLoadingAlta: false,
  successAlta: false,
  altaData: {},
  errorSendingAlta: {
    error: false,
    message: "",
  },
};

export const sendAlta = createAsyncThunk("alta/sendAlta", postAlta);

export const altaSlice = createSlice({
  name: "alta",
  initialState,
  reducers: {
    setAltaAccessData: (state, action) => ({
      ...state,
      accessData: action.payload,
    }),
    setAltaPersonalDataSignUp: (state, action) => ({
      ...state,
      personalData: action.payload,
    }),
    setFaceToFaceData: (state, action) => ({
      ...state,
      faceToFaceData: action.payload,
    }),
    setNoFaceToFaceData: (state, action) => ({
      ...state,
      noFaceToFaceData: action.payload,
    }),
    setVirtualData: (state, action) => ({
      ...state,
      virtual: action.payload,
    }),
    setDigitalData: (state, action) => ({
      ...state,
      digital: action.payload,
    }),
    resetAltaSignUpData: (state) => ({
      ...state,
      accessData: initialState.accessData,
      personalData: initialState.personalData,
      faceToFaceData: initialState.faceToFaceData,
      noFaceToFaceData: initialState.noFaceToFaceData,
      virtual: initialState.virtual,
      digital: initialState.digital,
      isLoadingAlta: initialState.isLoadingAlta,
      successAlta: initialState.successAlta,
      altaData: initialState.altaData,
      errorSendingAlta: initialState.errorSendingAlta,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(sendAlta.pending, (state) => {
      state.isLoadingAlta = true;
      state.successAlta = false;
      state.altaData = {};
      state.errorSendingAlta = {
        error: false,
        message: "",
      };
    });
    builder.addCase(sendAlta.fulfilled, (state, action) => {
      state.isLoadingAlta = false;
      state.successAlta = true;
      state.altaData = action.payload;
      state.errorSendingAlta = {
        error: false,
        message: "",
      };
    });
    builder.addCase(sendAlta.rejected, (state, action) => {
      state.isLoadingAlta = false;
      state.successAlta = false;
      state.altaData = {};
      state.errorSendingAlta = {
        error: true,
        message: action.error.message,
      };
    });
  },
});

export const {
  setAltaAccessData,
  setAltaPersonalDataSignUp,
  setFaceToFaceData,
  setNoFaceToFaceData,
  setVirtualData,
  setDigitalData,
  resetAltaSignUpData,
} = altaSlice.actions;

export default altaSlice.reducer;
