import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessData: {
    userType: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  },
  personalData: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    documentType: "",
    documentNumber: "",
    phone: "",
    country: "",
  },
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    setAccessData: (state, action) => ({
      ...state,
      accessData: action.payload,
    }),
    setPersonalDataSignUp: (state, action) => ({
      ...state,
      personalData: action.payload,
    }),
    resetSignUpData: (state) => ({
      ...state,
      accessData: initialState.accessData,
      personalData: initialState.personalData,
    }),
  },
});

export const { setAccessData, setPersonalDataSignUp, resetSignUpData } =
  signUpSlice.actions;

export default signUpSlice.reducer;
