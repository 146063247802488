import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { setInitial } from "../../../store/slices/stepSlice";
import { resetUploadedPhoto } from "../../../store/slices/photographerSlice";
import { useTranslation } from "react-i18next";

const CompletedProccessView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    photographer: {
      contenderChallengesData: { challengeName, divisionName },
    },
    auth: { userTypeId },
    qrHandler: {
      qrData: { challengeId, contenderId, divisionId, eventId },
    },
  } = useSelector((state) => state);

  const finishProccess = () => {
    navigate("/default-view");
    dispatch(resetUploadedPhoto());
    dispatch(setInitial());
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mx-2">
        <CardCompetition title={t("COMPETITION")} subtitle={challengeName} />
        <div className="flex gap-x-1.5 my-1">
          <CardDivision title={t("DIVISION")} subtitle={divisionName} />
        </div>
      </div>
      <img
        src={`http://inja-api.guadcore.ar/api/Photographer/GetImage?eventId=${eventId}&challengeId=${challengeId}&divisionId=${divisionId}&contenderId=${contenderId}&photographerId=${userTypeId}`}
         className="w-full h-auto"
         alt="uploaded"
      />
      <Button
        type="button"
        onClick={finishProccess}
        customClass="w-full mt-auto sticky bottom-0"
        label={t("FINALIZE")}
      />
    </div>
  );
};

export default CompletedProccessView;
