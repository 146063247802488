import React from "react";
import { useTranslation } from "react-i18next";

const Modal = ({ isOpen, onCloseModal, message }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    onCloseModal();
  };

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={handleModalClick}
        >
          <div className="flex flex-col w-3/4 text-white p-4 content-center justify-center bg-lilac-700 rounded">
            {message}
            <button
              className="bg-lilac-200 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-2"
              onClick={closeModal}
            >
              {t("CLOSE")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
