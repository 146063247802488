import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteDeduction,
  getContenderDeduction,
  putAddDeduction,
} from "../../service/providers/providerDeduction";

const initialState = {
  isLoadingContenderDeduction: false,
  errorFetchingContenderDeduction: false,
  successFetchingContenderDeduction: false,
  contenderDeductionData: [],
  isLoadingPostingDeduction: false,
  errorPostingDeduction: false,
  successPostingDeduction: false,
  deductionToEdit: {},
  isEditingDeduction: false,
  deductionnumberToEdit: null,
  isLoadingDeleteDeduction: false,
  errorDeletingDeduction: false,
  successDeletingDeduction: false,
};

export const fetchContenderDeduction = createAsyncThunk(
  "deduction/fetchContenderDeduction",
  getContenderDeduction
);

export const addDeductionData = createAsyncThunk(
  "deduction/putAddDeductionData",
  putAddDeduction
);

export const deleteDeductionContender = createAsyncThunk(
  "deduction/deleteDeduction",
  deleteDeduction
);

const deductionSlice = createSlice({
  name: "deduction",
  initialState,
  reducers: {
    resetContenderDeduction: (state) => ({
      ...state,
      successFetchingContenderDeduction: false,
    }),
    updatedeductionData: (state, action) => ({
      ...state,
      contenderDeductionData: action.payload,
    }),
    addDeductionToEdit: (state, action) => ({
      ...state,
      deductionToEdit: action.payload,
    }),
    resetDeductionToEdit: (state) => ({
      ...state,
      deductionToEdit: {},
    }),
    setIsEditingDeduction: (state, action) => ({
      ...state,
      isEditingDeduction: action.payload,
    }),
    setDeductionnumberToEdit: (state, action) => ({
      ...state,
      deductionnumberToEdit: action.payload,
    }),
    resetDeleteDeduction: (state, action) => ({
      ...state,
      isLoadingDeleteDeduction: false,
      errorDeletingDeduction: false,
      successDeletingDeduction: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContenderDeduction.pending, (state) => {
      state.isLoadingContenderDeduction = true;
      state.errorFetchingContenderDeduction = false;
      state.successFetchingContenderDeduction = false;
    });
    builder.addCase(fetchContenderDeduction.fulfilled, (state, action) => {
      state.isLoadingContenderDeduction = false;
      state.successFetchingContenderDeduction = true;
      state.contenderDeductionData = action.payload;
    });
    builder.addCase(fetchContenderDeduction.rejected, (state, action) => {
      state.isLoadingContenderDeduction = false;
      state.errorFetchingContenderDeduction = action.error.message;
      state.successFetchingContenderDeduction = false;
    });
    builder.addCase(addDeductionData.pending, (state) => {
      state.isLoadingPostingDeduction = true;
      state.errorPostingDeduction = false;
      state.successPostingDeduction = false;
    });
    builder.addCase(addDeductionData.fulfilled, (state) => {
      state.isLoadingPostingDeduction = false;
      state.errorPostingDeduction = false;
      state.successPostingDeduction = true;
    });
    builder.addCase(addDeductionData.rejected, (state, action) => {
      state.isLoadingPostingDeduction = false;
      state.errorPostingDeduction = action.error.message;
      state.successPostingDeduction = false;
    });
    builder.addCase(deleteDeductionContender.pending, (state) => {
      state.isLoadingDeleteDeduction = true;
      state.errorDeletingDeduction = false;
      state.successDeletingDeduction = false;
    });
    builder.addCase(deleteDeductionContender.fulfilled, (state) => {
      state.isLoadingDeleteDeduction = false;
      state.errorDeletingDeduction = false;
      state.successDeletingDeduction = true;
    });
    builder.addCase(deleteDeductionContender.rejected, (state, action) => {
      state.isLoadingDeleteDeduction = false;
      state.errorDeletingDeduction = action.error.message;
      state.successDeletingDeduction = false;
    });
  },
});

export const {
  resetContenderDeduction,
  updatedeductionData,
  addDeductionToEdit,
  resetDeductionToEdit,
  setIsEditingDeduction,
  setDeductionnumberToEdit,
  resetDeleteDeduction,
} = deductionSlice.actions;

export default deductionSlice.reducer;
