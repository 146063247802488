import apiClient from "../apiClient";

const getContenderDeduction = async ({ params: { eventId, contenderId } }) => {
  const response = await apiClient.get("Deduction/ContenderDeductions", {
    params: {
      eventId,
      contenderId,
    },
  });
  return response.data;
};

const putAddDeduction = async ({
  deductionnumber,
  eventId,
  challengeid,
  divisionid,
  judgeid,
  contenderId,
  score,
  comment,
}) => {
  const response = await apiClient.put(
    `Deduction/AddDeduction?deductionnumber=${deductionnumber}&eventId=${eventId}&challengeid=${challengeid}&divisionid=${divisionid}&judgeid=${judgeid}&contenderid=${contenderId}&score=${score}&comment=${comment}`
  );
  return response.data;
};

const deleteDeduction = async ({
  deductionnumber,
  eventId,
  challengeid,
  divisionid,
  judgeid,
  contenderid,
}) => {
  const response = await apiClient.delete(
    `Deduction/DeleteDeduction?deductionnumber=${deductionnumber}&eventId=${eventId}&challengeid=${challengeid}&divisionid=${divisionid}&judgeid=${judgeid}&contenderid=${contenderid}`
  );
  return response.data;
};

export { getContenderDeduction, putAddDeduction, deleteDeduction };
