import React, { useEffect, useState } from "react";
import logo from "../../../assets/beauty-icon.jpeg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFromSearchParams } from "../../../helpers/urlHelper";
import { useDispatch, useSelector } from "react-redux";
import { updateQrHandler } from "../../../store/slices/qrHandlerSlice";
import {
  fetchJudgeData,
  resetJudgeDataError,
  updateActualRound,
  updateDataLength,
} from "../../../store/slices/judgeSlice";
import { setIsCompleted } from "../../../store/slices/stepSlice";
import { ROUNDS } from "../../../constanst/constants";
import {
  getContenderChallengesData,
  resetContenderChallengesError,
} from "../../../store/slices/photographerSlice";
import { fetchContenderDeduction } from "../../../store/slices/deductionSlice";
import {
  fetchContenderPoints,
  resetContenderPointsError,
} from "../../../store/slices/userSlice";
import Modal from "../../../components/modal/Modal";
import Header from "../../../components/header/Header";
import {
  fetchContenderData,
  resetContenderDataError,
} from "../../../store/slices/contenderSlice";
import { useTranslation } from "react-i18next";

const QRHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();
  const {
    auth: { role, userTypeId },
    contender: {
      // isLoadingContender,
      successFetchingContenderData,
      errorFetchingContenderData,
    },
    deduction: { successFetchingContenderDeduction },
    judge: {
      judgeDataToPointing: { round1, round2 },
      actualRound,
      errorFetchingJudgeData,
    },
    photographer: {
      successFetchingContenderChallengesData,
      errorFetchingContenderChallengesData,
    },
    user: { successFetchingContenderPoints, errorFetchingContenderPoints },
  } = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  const challengeId =
    getFromSearchParams({ searchParams, key: "challengeId" }) || "";
  const contenderId =
    getFromSearchParams({ searchParams, key: "contenderId" }) || "";
  const divisionId =
    getFromSearchParams({ searchParams, key: "divisionId" }) || "";
  const eventId = getFromSearchParams({ searchParams, key: "eventId" }) || "";

  const fetchDataForRole = {
    1: (params) => dispatch(fetchContenderData(params)),
    2: (params) => dispatch(getContenderChallengesData(params)),
    3: (params) => dispatch(fetchJudgeData(params)),
    6: (params) => dispatch(fetchContenderPoints(params)),
  };
  const body = {
    1: { params: { contenderId: userTypeId } },
    2: { params: { eventId, challengeId, divisionId, contenderId } },
    3: { params: { eventId, challengeId, judgeId: userTypeId, contenderId } },
    6: { params: { eventId, challengeId, divisionId, contenderId } },
  };

  const resetError = {
    1: () => dispatch(resetContenderDataError()),
    2: () => dispatch(resetContenderChallengesError()),
    3: () => dispatch(resetJudgeDataError()),
    6: () => dispatch(resetContenderPointsError()),
  };

  useEffect(() => {
    if (errorFetchingJudgeData.error) {
      setIsOpenModal(true);
    }
  }, [errorFetchingJudgeData]);

  useEffect(() => {
    if (errorFetchingContenderPoints.error) {
      setIsOpenModal(true);
    }
  }, [errorFetchingContenderPoints]);

  useEffect(() => {
    if (errorFetchingContenderChallengesData.error) {
      setIsOpenModal(true);
    }
  }, [errorFetchingContenderChallengesData]);

  useEffect(() => {
    if (errorFetchingContenderData.error) {
      setIsOpenModal(true);
    }
  }, [errorFetchingContenderData]);

  useEffect(() => {
    if ((challengeId, contenderId)) {
      dispatch(
        updateQrHandler({
          challengeId: parseInt(challengeId),
          contenderId: parseInt(contenderId),
          divisionId: parseInt(divisionId),
          eventId: parseInt(eventId),
        })
      );
      fetchDataForRole[role](body[role]);
    }
  }, [challengeId, contenderId]);

  useEffect(() => {
    if (role === 1) {
      fetchDataForRole[1](body[1]);
    }
  }, [role]);

  useEffect(() => {
    if (round1?.length > 0) {
      dispatch(updateActualRound(ROUNDS[1]));
      dispatch(updateDataLength(round1.length));
    }
    if (round2?.length > 0 && round1?.length === 0) {
      dispatch(updateActualRound(ROUNDS[2]));
      dispatch(updateDataLength(round2.length));
    }
    if (round2?.length === 0 && round1?.length === 0) {
      dispatch(updateActualRound(ROUNDS[3]));
      dispatch(setIsCompleted(true));
    }
  }, [round1, round2]);

  useEffect(() => {
    if (Object.values(ROUNDS).some((ele) => ele === actualRound)) {
      navigate("/judge");
    }
  }, [actualRound]);

  useEffect(() => {
    if (successFetchingContenderChallengesData) {
      navigate("/photographer");
    }
  }, [successFetchingContenderChallengesData]);

  useEffect(() => {
    if (successFetchingContenderPoints) {
      dispatch(fetchContenderDeduction({ params: { eventId, contenderId } }));
    }
  }, [successFetchingContenderPoints]);

  useEffect(() => {
    if (successFetchingContenderDeduction) {
      navigate("/supervisor");
    }
  }, [successFetchingContenderDeduction]);

  useEffect(() => {
    if (successFetchingContenderData) {
      navigate("/contender");
    }
  }, [successFetchingContenderData]);

  const onCloseModal = () => {
    setIsOpenModal((prevState) => !prevState);
    resetError[role]();
    navigate("/default-view");
  };

  return (
    <div className="flex flex-col  h-full">
      <Modal
        isOpen={isOpenModal}
        onCloseModal={onCloseModal}
        message= {t("QR_ERROR_MESSAGE")}
      />
      <Header />
      <img src={logo} alt="logo" className="h-48 w-64 ml-auto mr-auto mt-20" />
      <h1 className="mt-10 ml-auto mr-auto font-semibold">
       {t("QR_WAITING_MESSAGE")}
      </h1>
    </div>
  );
};

export default QRHandler;
