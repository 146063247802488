import { Field, useField } from "formik";
import React from "react";

const RadioButtonGroup = ({ fields = [], name, onChange, disabled }) => {
  const [field, meta, { setValue }] = useField(name);

  const handleChange = (event) => {
    // Actualizar el valor de Formik cuando cambie un radio button
    setValue(event.target.value);

    // Ejecutar la función onChange proporcionada
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div
      role="group"
      aria-labelledby="my-radio-group"
      className="flex flex-col"
    >
      {fields?.map((fieldOption) => (
        <label className="ml-2" key={fieldOption.id}>
          <Field
            type="radio"
            onChange={handleChange}
            name={name}
            value={fieldOption.value}
            checked={field.value === fieldOption.value}
            disabled={disabled}
            className="mr-2"
          />
          {fieldOption.label}
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
