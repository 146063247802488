import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import logo from "../../assets/beauty-icon.jpeg";
import { setIsSingingUp } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Ingreso = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-screen">
      <div>
        <img
          src={logo}
          alt="beautycomp Logo"
          className="h-48 w-64 ml-auto mr-auto mt-20"
        />
        {process.env.REACT_APP_ENVIRONMENT === "test" && (
          <h1 className="m-4 text-center">TESTING</h1>
        )}
      </div>
      <div className="flex flex-col mt-auto">
        <Button
          customClass="mb-3 fixed bottom-10 w-full"
          label={t("SIGN_IN")}
          onClick={() => {
            dispatch(setIsSingingUp(true));
            navigate("/sign-up");
          }}
        />
        <Button
          customClass="w-full mt-auto fixed bottom-0"
          label={t("ENTER_USER")}
          onClick={() => navigate("/login")}
        />
      </div>
    </div>
  );
};

export default Ingreso;
