import { Field } from "formik";
import React from "react";

const Checkbox = ({ name, label, value, comment, disabled, onChange }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <>
          <label htmlFor={label}>
            <input
              {...field}
              id={label}
              className="mr-2"
              type="checkbox"
              checked={field?.value?.includes(value)}
              disabled={disabled}
              onClick={(e) => {
                if (disabled) e.preventDefault();
                if (onChange) onChange();
              }}
              onChange={(e) => {
                const set = new Set(field.value);
                if (set.has(value)) {
                  set.delete(value);
                } else {
                  set.add(value);
                }
                form.setFieldValue(field.name, Array.from(set));
                form.setFieldTouched(field.name, true);
                if (onChange) onChange();
              }}
            />
            {label}
          </label>
          <span className="ml-6 font-light">{comment}</span>
        </>
      )}
    </Field>
  );
};

export default Checkbox;
