import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qrData: [],
};

const qrHandlerSlice = createSlice({
  name: "qrHandler",
  initialState,
  reducers: {
    updateQrHandler: (state, action) => ({
      ...state,
      qrData: action.payload,
    }),
  },
});

export const { updateQrHandler } = qrHandlerSlice.actions;

export default qrHandlerSlice.reducer;
