import React from "react";

const THEME = {
  default: "bg-lilac-200",
  dark: "bg-lilac-700",
  red: "bg-red-100",
};

const Button = ({
  label,
  customClass,
  onClick,
  disabled = false,
  theme = "default",
  ...props
}) => (
  <button
    className={`px-4 py-2 ${THEME[theme]} ${
      theme === "dark" ? "rounded" : ""
    } text-lilac-100 duration-300 ${customClass} disabled:bg-gray-400`}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {label}
  </button>
);

export default Button;
