import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../../service/providers/providerCountries";
import { parsedOptions } from "../../helpers/commonHelpers";

const initialState = {
  isLoadingCountries: false,
  countriesData: [],
  countriesOptions: [],
  errorFetchingCountries: {
    error: false,
    message: "",
  },
  successFetchingCountries: false,
};

export const fetchCountries = createAsyncThunk(
  "countries/fetchCountries",
  getCountries
);

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.isLoadingCountries = true;
      state.countriesData = [];
      state.countriesOptions = [];
      state.errorFetchingCountries = {
        error: false,
        message: "",
      };
      state.successFetchingCountries = false;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.isLoadingCountries = false;
      state.countriesData = action.payload;
      state.countriesOptions = parsedOptions(action.payload);
      state.errorFetchingCountries = {
        error: false,
        message: "",
      };
      state.successFetchingCountries = true;
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.isLoadingCountries = false;
      state.countriesData = [];
      state.countriesOptions = [];
      state.errorFetchingCountries = {
        error: true,
        message: action.error.message,
      };
      state.successFetchingCountries = false;
    });
  },
});

export default countriesSlice.reducer;
