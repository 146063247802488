const HANDS = {
  1: "HAND_ONE",
  2: "HAND_LEFT",
};

const ROUNDS = {
  1: "round1",
  2: "round2",
  3: "finish",
};

const DEFAULT_SLOT = {
  slot1: null,
  slot2: null,
  slot3: null,
  slot4: null,
  slot5: null,
  slot6: null,
  slot7: null,
  slot8: null,
  slot9: null,
  slot10: null,
};

const ROLES = {
  1: "ROLE_COMPETITOR",
  2: "ROLE_PHOTOGRAPHER",
  3: "ROLE_JUDGE",
  4: "ROLE_ORGANIZER",
  5: "ROLE_ADMIN",
  6: "ROLE_SUPERVISOR",
};

export { HANDS, ROUNDS, DEFAULT_SLOT, ROLES };
