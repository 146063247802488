import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import logo from "../../../assets/beauty-icon.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { setInitial } from "../../../store/slices/stepSlice";
import { resetAltaSignUpData } from "../../../store/slices/altaSlice";
import {
  resetUserByEmailData,
  resetUserNewContenderData,
} from "../../../store/slices/userSlice";
import Spinner from "../../../components/spinner/Spinner";

const CompletedProcessAltaView = ({ name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    alta: {
      isLoadingAlta,
      successAlta,
      errorSendingAlta: { error, message },
    },
  } = useSelector((state) => state);

  const finishProcess = () => {
    navigate("/");
    dispatch(setInitial());
    dispatch(resetAltaSignUpData());
    dispatch(resetUserNewContenderData());
    dispatch(resetUserByEmailData());
  };

  if (isLoadingAlta) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col justify-between h-full text-center p-4">
      <div>
        <img
          src={logo}
          alt="logo"
          className="h-40 w-56 ml-auto mr-auto mt-10"
        />
        <h1 className="text-xl font-bold mt-4">{name}</h1>
      </div>
      {successAlta && (
        <div>
          <h2 className="text-2xl font-bold">{t("CONGRATULATIONS")}</h2>
          <p>{t("INSCRIPTION_OK")}</p>
        </div>
      )}
      {error && (
        <div>
          <h2 className="text-2xl font-bold">{t("ERROR_INSCRIPTION")}</h2>
          <p>{message}</p>
        </div>
      )}
      <Button label={t("FINALIZE")} onClick={finishProcess} />
    </div>
  );
};

export default CompletedProcessAltaView;
