import apiClient from "../apiClient";

const getContenderPoints = async ({
  params: { eventId, challengeId, divisionId, contenderId },
}) => {
  const response = await apiClient.get("User/GetContenderPoints", {
    params: {
      eventId,
      challengeId,
      divisionId,
      contenderId,
    },
  });
  return response.data;
};

const getContenderParticipation = async ({ params: { contenderId } }) => {
  const response = await apiClient.get("User/GetContenderParticipation", {
    params: {
      contenderId,
    },
  });
  return response.data;
};

const getUserByEmail = async (email) => {
  const response = await apiClient.get(`User/UserByEmail/${email}`);
  return response.data;
};

const postNewContender = async (body) => {
  const response = await apiClient.post("User/NewContender", body);
  return response.data;
};

const putChangeUserLanguage = async ({
  anEmail,
  newLanguage,
}) => {
  const response = await apiClient.put(
    `User/ChangeUserLanguage?anEmail=${anEmail}&newLanguage=${newLanguage}`
  );
  return response.data;
};


export {
  getContenderPoints,
  getContenderParticipation,
  getUserByEmail,
  postNewContender,
  putChangeUserLanguage,
};
