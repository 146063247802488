const eventParser = (data) => {
  const dataParsed = [{ id: 0, value: "", label: "Seleccione una opcion" }];
  data.map((ele) =>
    dataParsed.push({
      id: ele.id,
      value: ele.name,
      label: ele.name,
    })
  );
  return dataParsed;
};

const getEventCod = (options, selected) => {
  const selectedObj = options.find((ele) => ele.value === selected);
  return selectedObj.id;
};

export { eventParser, getEventCod };
