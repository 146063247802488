import React from "react";
import "./cardDivision.css";

const THEME = {
  light: "card__container--light",
  dark: "card__container--dark",
};

const CardDivision = ({
  title,
  subtitle,
  theme = "light",
  customClass,
  twoColumns = false,
  disabled = false,
  secondTitle = "",
  secondSubtitle = "",
  thirdTitle = "",
  thirdSubtitle = "",
  ...props
}) =>
  !twoColumns || disabled ? (
    <div
      className={`${customClass}  ${
        disabled ? "bg-lilac-100 text-lilac-200 items-start p" : THEME[theme] + ' items-center'
      } flex flex-col rounded-lg card__container w-full p-2`}
      {...props}
    >
      <span className={`${disabled ? "text-lilac-200" : "card__title"}`}>
        {title}
      </span>
      <span
        className={`font-bold text-lg ${
          disabled ? "text-lilac-200" : "text-white"
        }`}
      >
        {subtitle}
      </span>
    </div>
  ) : !thirdTitle ? (
    <div
      className={`${THEME[theme]} ${customClass} flex justify-between rounded-lg card__container w-full p-2`}
      {...props}
    >
      <div className="flex flex-col items-start justify-center">
        <span className="card__title">{title}</span>
        <span className="font-bold text-lg text-white">{subtitle}</span>
      </div>
      <div className="flex flex-col items-center py-2">
        <span className="card__title">{secondTitle}</span>
        <span className="font-bold text-lg text-white">{secondSubtitle}</span>
      </div>
    </div>
  ) : (
    <div
      className={`${THEME[theme]} ${customClass} flex justify-between rounded-lg card__container w-full p-2`}
      {...props}
    >
      <div className="flex flex-grow items-center justify-start">
        <span className="card__title">{title}</span>
        <span className="font-bold text-lg text-white">{subtitle}</span>
      </div>
      <div className="flex">
        <div className="flex flex-col items-center py-2 mx-2">
          <span className="card__title">{secondTitle}</span>
          <span className="font-bold text-lg text-white">{secondSubtitle}</span>
        </div>
        <div className="flex flex-col items-center py-2">
          <span className="card__title">{thirdTitle}</span>
          <span className="font-bold text-lg text-white">{thirdSubtitle}</span>
        </div>
      </div>
    </div>
  );

export default CardDivision;
