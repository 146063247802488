import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../../../components/button/Button";
import CheckBoxGroup from "../../../components/checkboxGroup/CheckBoxGroup";
import RadioButtonGroup from "../../../components/radioButtonGroup/RadioButtonGroup";
import Textarea from "../../../components/textarea/Textarea";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { useDispatch, useSelector } from "react-redux";
import { setNoFaceToFaceData } from "../../../store/slices/altaSlice";
import Header from "../../../components/header/Header";
import { parsedChallengesByDivision } from "../../../helpers/eventHelper";

const CompetitionNoFaceToFaceView = ({ handleNextStep, name }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    alta: {
      noFaceToFaceData: {
        challengesSelected,
        divisionSelected,
        primerLugar,
        lastFirst,
      },
    },
    event: {
      eventByIdData: { competitionTypes },
      offSiteChallenges,
      offSiteDivisions,
    },
  } = useSelector((state) => state);
  const [parsedChallenges, setParsedChallenges] = useState(offSiteChallenges);

  useEffect(() => {
    if (offSiteChallenges.length === 0 && offSiteDivisions.length === 0) {
      handleNextStep();
    }
  }, []);

  const primerFields = [
    {
      id: 1,
      value: "true",
      label: t("YES"),
    },
    {
      id: 2,
      value: "false",
      label: t("NO"),
    },
  ];

  const validationSchema = Yup.object().shape({
    divisionSelected: Yup.string().required(t("REQUIRED_FIELD")),
    challengesSelected: Yup.array().required(t("REQUIRED_FIELD")),
  });

  const onSubmit = (data) => {
    dispatch(setNoFaceToFaceData(data));
    handleNextStep();
  };

  const handleChangeDivision = (value) => {
    const newParsedChallenges = parsedChallengesByDivision(
      competitionTypes,
      "Off-site",
      value
    );
    setParsedChallenges(newParsedChallenges.parsedChallenges);
  };

  return (
    <div className="h-full flex flex-col">
      <Header />
      <Formik
        initialValues={{
          subscribe: "false",
          divisionSelected,
          challengesSelected,
          primerLugar,
          lastFirst,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isValid }) => (
          <Form className="h-full flex flex-col p-4">
            <CardCompetition
              title={t("INSCRIPTION_FORM")}
              subtitle={name}
            />
            <h2 className="font-bold mt-2">
              {t("WANT_INSCRIT_NO_INPERSON")}
            </h2>
            <RadioButtonGroup name="subscribe" fields={primerFields} />
            <h2 className="font-bold mt-2">
              {t("CHOOSE_NO_IN_PERSON_DIVISION")}
            </h2>
            <RadioButtonGroup
              name="divisionSelected"
              fields={offSiteDivisions}
              disabled={values.subscribe === "false"}
              onChange={(event) => handleChangeDivision(event)}
            />
            <CheckBoxGroup
              legend={`${t("NO_IN_PERSON_COMPETITIONS")}:`}
              fields={parsedChallenges}
              disabled={values.subscribe === "false"}
              name="challengesSelected"
              selected={values.challengesSelected}
            />
            <h2 className="font-bold mt-2">
              {t("NO_IN_PERSON_FIRST_PLACE")}
            </h2>
            <RadioButtonGroup name="primerLugar" fields={primerFields} />
            <Textarea
              name="lastFirst"
              id="lastFirst"
              numRow={4}
              label={t("NO_IN_PERSON_LAST_FIRST_PLACE")}
            />
            {values.subscribe === "true" ? (
              <Button
                customClass="w-full mt-auto sticky bottom-0"
                label={t("CONTINUE")}
                disabled={!isValid && values.subscribe === "true"}
                type="submit"
              />
            ) : (
              <Button
                customClass="w-full mt-auto sticky bottom-0"
                label={t("CONTINUE")}
                disabled={!isValid && values.subscribe === "true"}
                onClick={() => handleNextStep()}
                type="button"
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompetitionNoFaceToFaceView;
