import React, { useEffect, useState } from "react";
import TotalPoints from "../../../components/totalPoints/TotalPoints";
import Textarea from "../../../components/textarea/Textarea";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { useDispatch, useSelector } from "react-redux";
import { postSendPoints, resetPoints } from "../../../store/slices/pointsSlice";
import { setIsCompleted } from "../../../store/slices/stepSlice";
import Spinner from "../../../components/spinner/Spinner";
import {
  goToNextIndex,
  setIsEditingJudge,
  updateDataJudge,
} from "../../../store/slices/judgeSlice";

const SumaryView = ({ handleNextStep }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [point, setPoint] = useState(null);
  const {
    judge: {
      currentIndex,
      dataLength,
      judgeData,
      judgeDataToPointing,
      actualRound,
      photoURL,
      isEditing,
    },
    points: {
      actualPoints,
      totalPoints,
      successSendingPoints,
      isLoadingSendPoints,
    },
  } = useSelector((state) => state);

  const round = judgeDataToPointing[actualRound];

  const {
    challengeName,
    contenderId,
    eventJudgeChallengeDivisionId,
    jugdementCriteriaNames,
    judgementcriterianame,
    maxscore,
    divisionName,
  } = round[currentIndex];

  const updateDataJudgeAfterSendingPoints = () => {
    const index = judgeData[actualRound].findIndex(
      (ele) => ele.judgementcriterianame === judgementcriterianame
    );
    if (index !== -1) {
      const newJudgeData = JSON.parse(JSON.stringify(judgeData));
      newJudgeData[actualRound][index].point = point;
      dispatch(updateDataJudge(newJudgeData));
    }
  };

  useEffect(() => {
    if (successSendingPoints) {
      updateDataJudgeAfterSendingPoints();
      dispatch(resetPoints());
      if (currentIndex + 1 < dataLength) {
        dispatch(goToNextIndex());
        handleNextStep();
      } else {
        dispatch(setIsCompleted(true));
        if (isEditing) {
          dispatch(setIsEditingJudge(false));
        }
      }
    }
  }, [successSendingPoints]);

  const onSubmit = (data) => {
    const body = {
      eventJudgeChallengeDivisionId,
      contenderId,
      ...actualPoints,
      ...data,
    };
    setPoint(body);
    dispatch(postSendPoints(body));
  };

  if (isLoadingSendPoints) {
    return <Spinner />;
  }

  return (
    <Formik
      initialValues={{
        comment: !isEditing ? "" : point?.comment,
      }}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form className="h-full flex flex-col mx-2">
            <CardCompetition
              title={t("COMPETITION")}
              subtitle={challengeName}
              photoURL={photoURL}
            />
            <div className="my-1">
              <CardDivision title={t("DIVISION")} subtitle={divisionName} />
            </div>
            <CardDivision
              theme="dark"
              title={t("CRITERION")}
              subtitle={jugdementCriteriaNames[i18n.language]}
            />
            <TotalPoints score={totalPoints} totalPoints={maxscore} />
            <Textarea name="comment" id="comment" numRow={6} label={t("NOTES")} customClass="comment"/>
            <Button
              type="submit"
              customClass="w-full mt-auto sticky bottom-0"
              label={t("CONTINUE")}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SumaryView;
