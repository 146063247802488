import { useEffect, useRef, useState } from "react";
import Perfil from "../../assets/perfil.svg";
import { useTranslation } from "react-i18next";

const UserMenu = ({ handleLogout, role }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const menuRef = useRef(null);

  const handleMenuToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={handleMenuToggle}
        className="flex items-center focus:outline-none mr-2"
      >
        <img src={Perfil} alt="menu handle" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-lilac-700 rounded shadow-md">
          <div className="px-4 py-2">
            <p className="text-gray-800">{role}</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-2">
            <button
              className="w-full text-left text-red-500"
              onClick={handleLogout}
            >
              {t("SIGN_OFF")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
