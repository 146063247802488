import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import CompetitonDetail from "../../../components/competitionDetail/CompetitionDetail";
import { setIsCompleted } from "../../../store/slices/stepSlice";
import { sendAlta } from "../../../store/slices/altaSlice";
import Header from "../../../components/header/Header";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import { parsedEventForAlta } from "../../../helpers/eventHelper";

const BillingViewAlta = ({ name }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    alta: {
      accessData: { email },
      faceToFaceData,
      noFaceToFaceData,
      virtual,
      digital,
    },
    event: {
      eventByIdData,
      onSiteChallenges,
      virtualChallenges,
      digitalChallenges,
      offSiteChallenges,
    },
    user: { newContenderData, userByEmailData },
  } = useSelector((state) => state);

  const onSubmit = () => {
    const userId = newContenderData || userByEmailData.id;
    const body = parsedEventForAlta(
      email,
      userId,
      eventByIdData,
      faceToFaceData,
      noFaceToFaceData,
      virtual,
      digital
    );
    dispatch(sendAlta(body));
    dispatch(setIsCompleted(true));
  };

  return (
    <div className="h-full flex flex-col">
      <Header />
      <div className="h-full p-4 flex flex-col">
        <CardCompetition title={t("INSCRIPTION_FORM")} subtitle={name} />
        <h1>{t("RESUME_INSCRIPTION")}</h1>
        <CompetitonDetail
          title={t("IN_PERSON_COMPETITONS")}
          challenges={onSiteChallenges}
          challengesSelected={faceToFaceData?.challengesSelected}
        />
        <CompetitonDetail
          title={t("VIRTUAL_COMPETITONS")}
          challenges={virtualChallenges}
          challengesSelected={virtual?.challengesSelected}
        />
        <CompetitonDetail
          title={t("DIGITAL_COMPETITONS")}
          challenges={digitalChallenges}
          challengesSelected={digital?.challengesSelected}
        />
        <CompetitonDetail
          title={t("NO_IN_PERSON_COMPETITONS")}
          challenges={offSiteChallenges}
          challengesSelected={noFaceToFaceData?.challengesSelected}
        />
        <Button
          onClick={onSubmit}
          customClass="w-full mt-auto sticky bottom-0"
          label={t("SEND_INFORMATION")}
          type="submit"
        />
      </div>
    </div>
  );
};

export default BillingViewAlta;
