import { configureStore } from "@reduxjs/toolkit";
import altaSlice from "./slices/altaSlice";
import authSlice from "./slices/authSlice";
import contenderSlice from "./slices/contenderSlice";
import countriesSlice from "./slices/countriesSlice";
import deductionSlice from "./slices/deductionSlice";
import documentSlice from "./slices/documentSlice";
import eventSlice from "./slices/eventSlice";
import judgeSlice from "./slices/judgeSlice";
import lenguajeSlice from "./slices/lenguajeSlice";
import photographerSlice from "./slices/photographerSlice";
import pointsSlice from "./slices/pointsSlice";
import qrHandlerSlice from "./slices/qrHandlerSlice";
import recoverPasswordSlice from "./slices/recoverPasswordSlice";
import signUpSlice from "./slices/signUpSlice";
import stepReducer from "./slices/stepSlice";
import userSlice from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    alta: altaSlice,
    auth: authSlice,
    contender: contenderSlice,
    countries: countriesSlice,
    deduction: deductionSlice,
    docType: documentSlice,
    event: eventSlice,
    judge: judgeSlice,
    lenguaje: lenguajeSlice,
    photographer: photographerSlice,
    points: pointsSlice,
    qrHandler: qrHandlerSlice,
    recoverPassword: recoverPasswordSlice,
    signUp: signUpSlice,
    step: stepReducer,
    user: userSlice,
  },
});
