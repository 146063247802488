import React from "react";
import "./deductionCard.css";
import { useTranslation } from "react-i18next";

const DeductionCard = ({ score, onEdit, onDelete, criterio }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-red-100 text-white deduction-card__container flex flex-col items-center my-4 py-1">
      <span className="deduction-card__puntos text-xs font-light">
        {criterio ? `${criterio}` : "Deducción"}
      </span>
      {onEdit && (
        <button className="deduction-card__editar" onClick={onEdit}>
          {t("EDIT")}
        </button>
      )}
      {onDelete && (
        <button className="deduction-card__editar" onClick={onDelete}>
          {t("DELETE_DEDUCTION")}
        </button>
      )}
      <span className="deduction-card__values font-semibold text-3xl">
        {score}
      </span>
    </div>
  );
};

export default DeductionCard;
