import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getContenderPoints,
  getUserByEmail,
  postNewContender,
} from "../../service/providers/providerUser";
import { parsedContenderPointsResponse } from "../../helpers/userHelper";

const initialState = {
  isLoadingContenderPoints: false,
  errorFetchingContenderPoints: {
    error: false,
    message: "",
  },
  successFetchingContenderPoints: false,
  contenderPoints: [],
  dataToEdit: {},
  isEditing: false,
  isLoadingUserByEmail: false,
  userByEmailData: null,
  errorFetchingUserByEmail: {
    error: false,
    message: "",
  },
  successUserByEmail: false,
  isLoadingNewContender: false,
  newContenderData: null,
  errorSendingNewContender: {
    error: false,
    message: "",
  },
  successSendingNewContender: false,
};

export const fetchContenderPoints = createAsyncThunk(
  "user/fetchContenderPoints",
  getContenderPoints
);

export const fetchUserByEmail = createAsyncThunk(
  "user/fetchUserByEmail",
  getUserByEmail
);

export const sendNewContender = createAsyncThunk(
  "user/sendNewContender",
  postNewContender
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateContenderPoints: (state, action) => ({
      ...state,
      contenderPoints: action.payload,
    }),
    addDataToEdit: (state, action) => ({
      ...state,
      dataToEdit: action.payload,
    }),
    resetDataToEdit: (state) => ({
      ...state,
      dataToEdit: {},
    }),
    setSuccessFetchingContenderPoints: (state, action) => ({
      ...state,
      successFetchingContenderPoints: action.payload,
    }),
    setIsEditing: (state, action) => ({
      ...state,
      isEditing: action.payload,
    }),
    resetContenderPointsError: (state) => ({
      ...state,
      errorFetchingContenderPoints: {
        error: false,
        message: "",
      },
    }),
    resetUserNewContenderData: (state) => ({
      ...state,
      isLoadingNewContender: false,
      newContenderData: null,
      errorSendingNewContender: {
        error: false,
        message: "",
      },
      successSendingNewContender: false,
    }),
    resetUserByEmailData: (state) => ({
      ...state,
      isLoadingUserByEmail: false,
      userByEmailData: null,
      errorFetchingUserByEmail: {
        error: false,
        message: "",
      },
      successUserByEmail: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContenderPoints.pending, (state) => {
      state.isLoadingContenderPoints = true;
      state.successFetchingContenderPoints = false;
      state.errorFetchingContenderPoints = {
        error: false,
        message: "",
      };
    });
    builder.addCase(fetchContenderPoints.fulfilled, (state, action) => {
      state.isLoadingContenderPoints = false;
      state.successFetchingContenderPoints = true;
      state.contenderPoints = parsedContenderPointsResponse(action.payload);
    });
    builder.addCase(fetchContenderPoints.rejected, (state, action) => {
      state.isLoadingContenderPoints = false;
      state.errorFetchingContenderPoints = {
        error: true,
        message: action.error.message,
      };
      state.successFetchingContenderPoints = false;
    });
    builder.addCase(fetchUserByEmail.pending, (state) => {
      state.isLoadingUserByEmail = true;
      state.errorFetchingUserByEmail = {
        error: false,
        message: "",
      };
      state.successUserByEmail = false;
    });
    builder.addCase(fetchUserByEmail.fulfilled, (state, action) => {
      state.isLoadingUserByEmail = false;
      state.userByEmailData = action.payload;
      state.errorFetchingUserByEmail = {
        error: false,
        message: "",
      };
      state.successUserByEmail = true;
    });
    builder.addCase(fetchUserByEmail.rejected, (state, action) => {
      state.isLoadingUserByEmail = false;
      state.userByEmailData = null;
      state.errorFetchingUserByEmail = {
        error: true,
        message: action.error.message,
      };
      state.successUserByEmail = false;
    });
    builder.addCase(sendNewContender.pending, (state) => {
      state.isLoadingNewContender = true;
      state.newContenderData = null;
      state.errorSendingNewContender = {
        error: false,
        message: "",
      };
      state.successSendingNewContender = false;
    });
    builder.addCase(sendNewContender.fulfilled, (state, action) => {
      state.isLoadingNewContender = false;
      state.newContenderData = action.payload;
      state.errorSendingNewContender = {
        error: false,
        message: "",
      };
      state.successSendingNewContender = true;
    });
    builder.addCase(sendNewContender.rejected, (state, action) => {
      state.isLoadingNewContender = false;
      state.newContenderData = null;
      state.errorSendingNewContender = {
        error: true,
        message: action.error.message,
      };
      state.successSendingNewContender = false;
    });
  },
});

export const {
  updateContenderPoints,
  addDataToEdit,
  resetDataToEdit,
  setSuccessFetchingContenderPoints,
  setIsEditing,
  resetContenderPointsError,
  resetUserNewContenderData,
  resetUserByEmailData,
} = userSlice.actions;

export default userSlice.reducer;
