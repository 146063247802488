import React from "react";
import Button from "../../../components/button/Button";
import CardCompetition from "../../../components/cardCompetition/CardCompetition";
import CardDivision from "../../../components/cardDivision/CardDivision";
import { useDispatch, useSelector } from "react-redux";
import { startProcess } from "../../../store/slices/stepSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InitialViewJudge = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    judge: {
      currentIndex,
      judgeDataToPointing,
      judgeData,
      actualRound,
      photoURL,
    },
  } = useSelector((state) => state);

  const round = judgeDataToPointing[actualRound];
  const { hands, challengeName, rounds, divisionName } = round[currentIndex];

  const handleClick = () => {
    dispatch(startProcess({ step: 1, checking: false }));
  };

  return (
    <div className="h-full flex flex-col">
      <div className="m-2 rounded">
        <CardCompetition
          title={t("COMPETITION")}
          subtitle={challengeName}
          backAction={() => navigate("/default-view")}
          photoURL={photoURL}
        />
        <div className="flex gap-x-1.5 my-1">
          <CardDivision title={t("DIVISION")} subtitle={divisionName} />
          {judgeData?.round2?.length > 0 && (
            <CardDivision theme="dark" title="Ronda" subtitle={rounds} />
          )}
          <CardDivision title={t("HAND")} subtitle={hands} />
        </div>
      </div>
      <img src={photoURL} alt="Foto" />
      <Button
        type="submit"
        customClass="w-full mt-auto sticky bottom-0"
        label={t("CONTINUE")}
        onClick={handleClick}
      />
    </div>
  );
};

export default InitialViewJudge;
