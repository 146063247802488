import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { QrReader } from "react-qr-reader";
import { Form, Formik } from "formik";
import Button from "../../../components/button/Button";
import Header from "../../../components/header/Header";
import ViewFinder from "./ViewFinder";
import Input from "../../../components/input/Input";
import Modal from "../../../components/modal/Modal";
import { useTranslation } from "react-i18next";
import "./DefaultView.css";

const DefaultView = () => {
  const navigate = useNavigate();
  const {
    auth: { role },
  } = useSelector((state) => state);
  const [startScan, setStartScan] = useState(false);
  const [scanDelay, setScanDelay] = useState(1000);
  const [searchWithCode, setSearchWithCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const { t } = useTranslation();

  const facingMode = "environment";

  const scannerStyle = {
    width: "100%",
    height: "auto",
  };

  useEffect(() => {
    if (role === 1) {
      navigate("/qr-handler");
    }
  }, [role]);

  const handleClick = () => {
    setStartScan(!startScan);
  };

  const handleScan = (result, error, codeReader) => {
    if (!!result) {
      console.log(`loaded data data`, result, codeReader, error);
      if (!result?.text) {
        setErrorMessage(t("ERROR_WRONG_QR"));
        setIsOpenModalError(true);
      } else {
        const navegateTo =
          result?.text.split("/")[result?.text.split("/").length - 1];
        if (navegateTo.startsWith("qr-handler?")) {
          navigate("/" + navegateTo);
          setScanDelay(false);
        } else {
          setErrorMessage(t("ERROR_WRONG_QR"));
          setIsOpenModalError(true);
        }
      }
    }
  };

  const onSubmit = (data) => {
    const code = data.code.split("-").map(Number);
    if (code.length === 4) {
      const navegateTo = `/qr-handler?challengeId=${code[0]}&divisionId=${code[1]}&contenderId=${code[2]}&eventId=${code[3]}`;
      navigate(navegateTo);
    } else {
      setErrorMessage(t("ERROR_WRONG_QR"));
      setIsOpenModalError(true);
    }
  };

  return (
    <div className="flex flex-col  h-full">
      <Header />
      <p className="mt-10 text-xl ml-auto mr-auto text-lilac-700 font-bold">
        {t("SCAN_QR_LABEL")}
      </p>
      <p className="text-base text-center text-lilac-700 mb-10">
        {t("SCAN_QR_MESSAGE")}
      </p>
      {startScan && (
        <>
          <QrReader
            ViewFinder={ViewFinder}
            constraints={{ facingMode: facingMode }}
            scanDelay={scanDelay}
            onResult={handleScan}
            videoId="video"
            style={scannerStyle}
          />
        </>
      )}
      {searchWithCode && (
        <Formik
          initialValues={{
            code: "",
          }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="flex flex-col mx-4">
              <p className="my-2 text-lg ml-auto mr-auto text-lilac-700 font-bold">
                {t("SCAN_QR_MANUAL")}
              </p>
              <Input name="code" id="code" type="text" label="" />
              <Button
                type="submit"
                customClass="w-full my-2"
                label={t("ENTER_LABEL")}
              />
            </Form>
          )}
        </Formik>
      )}
      <div className="mt-auto sticky bottom-0">
        <Button
          type="button"
          customClass="w-full mb-4"
          label={t("SCAN_QR_MANUAL")}
          onClick={() => setSearchWithCode((prev) => !prev)}
        />
        <Button
          type="button"
          customClass="w-full"
          label={startScan ? t("STOP_SCAN") : t("START_SCAN")}
          onClick={handleClick}
        />
      </div>
      <Modal
        isOpen={isOpenModalError}
        onCloseModal={() => setIsOpenModalError((prev) => !prev)}
        message={errorMessage}
      />
    </div>
  );
};

export default DefaultView;
