import React from "react";

const StepContainer = ({
  // step,
  children,
  // numberOfSteps,
  // onGoBack,
}) => <>{children}</>;

export default StepContainer;
